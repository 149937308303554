import React, { Component } from "react";
import {Modal} from "react-responsive-modal";
import 'react-responsive-modal/styles.css';

import Ink from "react-ink";
import { formatPrice } from "../../../../../helpers/formatPrice";
class OrderCancelPopup extends Component {
    state = {
        open: false
    };
    handlePopupOpen = () => {
        this.setState({ open: true });
    };
    handlePopupClose = () => {
        this.setState({ open: false });
    };
    cancelUserOrder=()=>{
        const {user,order}=this.props
        this.props.cancelOrder(user.data.auth_token, user.data.id, order.id);
        this.handlePopupClose();
    }
    render() {
        const { order } = this.props;

        return (
            <React.Fragment>
                <button
                    className="btn btn-square btn-sm btn-outline-danger mb-0 mt-15"
                    style={{ position: "relative", fontSize: "0.8rem" }}
                    onClick={this.handlePopupOpen}
                >
                    {localStorage.getItem("cancelOrderMainButton")}
                    <Ink duration="500" />
                </button>
                <Modal open={this.state.open} onClose={this.handlePopupClose} closeIconSize={32}>
                    <div className="text-center mt-100">
                        <div style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                            <i
                                className="si si-info"
                                style={{ fontSize: "4rem", opacity: "0.3", color: "#FF9800" }}
                            ></i>
                            <p>{order.ticket_uid}</p>
                            <p>{localStorage.getItem("orderCancellationConfirmationText")}</p>
                            {order.orderStatus === 'ORDER-PLACED' ? (
                                <React.Fragment>
                                    {order.payment_provider.payment_type !== "cash_on_spot" && (
                                        <p className="text-muted font-w400">
                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                            localStorage.getItem("currencyFormat")}
                                            {order.total}{" "}
                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                            localStorage.getItem("currencyFormat")}
                                            {localStorage.getItem("willBeRefundedText")}
                                        </p>
                                    )}
                                    {order.payment_provider.payment_type === "cash_on_spot" && order.total - order.payable !== 0 && (
                                        <p className="text-muted font-w400">
                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                            localStorage.getItem("currencyFormat")}
                                            {formatPrice(order.total - order.payable)}{" "}
                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                            localStorage.getItem("currencyFormat")}
                                            {localStorage.getItem("willBeRefundedText")}
                                        </p>
                                    )}
                                </React.Fragment>
                            ) : (
                                <p className="text-muted font-w400">{localStorage.getItem("willNotBeRefundedText")}</p>
                            )}
                        </div>
                        <div>
                            <button
                                className="btn btn-danger mr-3"
                                onClick={() => this.cancelUserOrder()}
                                style={{
                                    border: "0",
                                    borderRadius: "0",
                                    backgroundColor: localStorage.getItem("storeColor")
                                }}
                            >
                                {localStorage.getItem("yesCancelOrderBtn")}
                            </button>
                            <button
                                onClick={this.handlePopupClose}
                                className="btn "
                                style={{ border: "0", borderRadius: "0" }}
                            >
                                {localStorage.getItem("cancelGoBackBtn")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default OrderCancelPopup;
