import moment from "moment";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faUtensils} from "@fortawesome/free-solid-svg-icons";
export const testUserOrderDetails=()=>{

    return (localStorage.getItem("userOrderTime")!==null) && (localStorage.getItem("userRange")!==null)
}

export  const getApiOrderType=app_order_type=>{
    switch (app_order_type) {
        case 'DELIVERY':
            return 'delivery';
        case 'SELFPICKUP':
            return 'takeaway';
        case 'DINEIN':
            return 'sitin';
        default:
            return null;
    }
}



export const validateBooking=booking=>{
    if(booking.paymentStatus!=="UNPAID"){
        return false;
    }
    return moment(booking.fromDate).isAfter();

}

export const updateLocalStorageWithBooking=current_booking=>{
    let storage_booking=JSON.parse(localStorage.getItem('booking'));


    if(!storage_booking){
        console.log('set booking')
        localStorage.setItem('booking',JSON.stringify(current_booking));
        return true;
    }
    if(current_booking.id>storage_booking.id){

        localStorage.setItem('booking',JSON.stringify(current_booking));
    }
    return true;
}
export const removeBookingFromLocalStorage=()=>{
    localStorage.removeItem('booking');
    return true;
}


export const verifyMenuItemsMenuGroups=menu_items_menu_groups=>{
    let ok=false;
    menu_items_menu_groups.forEach(menu_group=>{
       if(menu_group.menu_items && menu_group.menu_items.length>0){
           ok=true;
       }
    });
    return ok;
}

export const showQuantity=quantity=>{
    return !quantity ? 0 : quantity;
}


export const generateUuid=()=>{
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export const getDefaultBookingTime=()=>{
    return  null;
    let userTime=localStorage.getItem('userOrderTime');
    if(!userTime){
        return  null;
    }


    if(moment(moment(userTime).format('DD/MM/YYYY HH:mm')).isAfter(moment().format('DD/MM/YYYY HH:mm'))){
        return  moment(userTime,'DD/MM/YYYY HH:mm').toDate();
    }
    return moment(moment().format('DD/MM/YYYY')+' '+moment(userTime,'DD/MM/YYYY HH:mm').format('HH:mm')).toDate();
}

export const getRestaurantAverageOrderTime=(cooking_time,delivery_time)=>{

    const time=localStorage.getItem("homePageMinsText");
    if(localStorage.getItem("userPreferredSelection")==='DELIVERY'){
        return (<React.Fragment><FontAwesomeIcon icon={faClock} /> {delivery_time}  {time}</React.Fragment>);
    }
    return ( <React.Fragment><FontAwesomeIcon icon={faUtensils} /> {cooking_time}  {time}</React.Fragment>);

}