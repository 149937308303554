import ContentLoader from "react-content-loader";
import React from "react";

function CustomContentLoader({height,width,speed,mainColor,secondaryColor}) {
    return(<ContentLoader
        height={height ? height :60}
        width={width ? width : 400}
        speed={speed ? speed : 1.2}
        foregroundColor={mainColor ? mainColor : '#f3f3f3'}
        backgroundColor={secondaryColor ? secondaryColor :'#ecebeb'}
    >
        <rect x="28" y="0" rx="0" ry="0" width="165" height="45" />
        <rect x="210" y="0" rx="0" ry="0" width="165" height="45" />
    </ContentLoader>)
}
export  default CustomContentLoader