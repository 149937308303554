import { GET_ORDERS, CANCEL_ORDER,NEW_ORDER,BOOKING_POS_RESPONSE ,CANCEL_BOOKING} from "./actionTypes";
import { GET_ORDERS_URL, GET_ORDER_CANCEL_URL,GENERATE_BOOKING_URL,POS_BOOKING_REQUEST_URL,CANCEL_BOOKING_URL } from "../../configs";
import Axios from "axios";

export const getOrders = (token, user_id) => dispatch => {
    Axios.post(GET_ORDERS_URL, {
        token: token,
        user_id: user_id
    })
        .then(response => {
            const orders = response.data;
            return dispatch({ type: GET_ORDERS, payload: orders });
        })
        .catch(function(error) {
            console.log(error);
        });
};


export const generateNewBooking=(
                                token,
                                user,
                              location,
                              restaurant_id,
                              order,
                              order_comment,
                              guests_number,
                              order_time,
                              order_type,
                             asap
                              ) => async dispatch => {
                                             try{
                             let {data}=await	Axios.post(GENERATE_BOOKING_URL, {
                                token,
                                 user,
                                 location,
                                restaurant_id,
                                order,
                                order_comment,
                                 guests_number,
                                 order_time,
                                 order_type,
                                 asap
            })
                    let booking=null;
                    if(data.success && data.booking){
                    booking=data.booking;
                    }
                    return dispatch({ type: NEW_ORDER, payload: booking });
            }catch(err){
            console.log(err)
            }
         };

export const bookingPosRequest=(token,booking_id,user_id)=>async dispatch=>{

        try{

 let {data}=await	Axios.post(POS_BOOKING_REQUEST_URL, {
                    token,
                    booking_id,
                    user_id
            })
            return dispatch({ type: BOOKING_POS_RESPONSE, payload: data });
        }catch(error){
        console.log(error)
        }
}

export const cancelBooking=(token,user_id,booking_id)=>async dispatch=>{

        try{

 let {data}=await	Axios.post(CANCEL_BOOKING_URL, {
                    token,
                           user_id,
                           booking_id
            })
            return dispatch({ type: CANCEL_BOOKING, payload: data });
        }catch(error){
        console.log(error)
        }
}


export const cancelOrder = (token, user_id, order_id) => dispatch => {
    Axios.post(GET_ORDER_CANCEL_URL, {
        token: token,
        user_id: user_id,
        booking_id: order_id
    })
        .then(response => {
            const res = response.data;
            return dispatch({ type: CANCEL_ORDER, payload: res });
        })
        .catch(function(error) {
            console.log(error);
        });
};
