import {GET_ORDERS, CANCEL_ORDER, NEW_ORDER, BOOKING_POS_RESPONSE, CANCEL_BOOKING} from "./actionTypes";

const initialState = {
    orders: [],
    cancel: null,
    booking:null,
    pos_response:null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ORDERS:
            return { ...state, orders: action.payload };
        case CANCEL_ORDER:
            return { ...state, cancel: action.payload };
        case NEW_ORDER:
            return { ...state, booking: action.payload };
        case BOOKING_POS_RESPONSE:
            return { ...state, pos_response: action.payload };
        case CANCEL_BOOKING:
            return { ...state, pos_response: action.payload };
        default:
            return state;
    }
}
