import { combineReducers } from "redux";
import settingsReducer from "./settings/reducer";
import notificationReducer from "./notification/reducer";
import promoSliderReducer from "./promoSlider/reducer";
import languageReducer from './languages/reducer';
import userReducer from "./user/reducer";
import ordersReducer from "./orders/reducer";
import ratingReducer from './rating/reducer';
import addressesReducer from "./addresses/reducer";
import popularLocationReducer from "./popularLocations/reducer";
import pagesReducer from "./pages/reducer";
import alertReducer from './alert/reducer';
import totalReducer from "./total/reducer";
import locationsReducer from "./locations/reducer";
import restaurantSearchReducer from "./searchRestaurants/reducer";
import couponReducer from "./coupon/reducer";
import cartReducer from "./cart/reducer";
import checkoutReducer from "./checkout/reducer";
import confirmCartReducer from './confirmCart/reducer';
import itemsReducer from "./items/reducer";
import restaurantReducer from "./restaurant/reducer";
import paymentGatewayReducer from "./paymentgateways/reducer";
import vivaWalletReducer from "./vivaWallet/reducer";
export default combineReducers({
    settings: settingsReducer,
    notification_token: notificationReducer,
    promo_slides: promoSliderReducer,
    languages: languageReducer,
    user: userReducer,
    orders:ordersReducer,
    rating:ratingReducer,
    addresses: addressesReducer,
    popular_locations: popularLocationReducer,
    pages: pagesReducer,
    alert: alertReducer,
    total:totalReducer,
    locations: locationsReducer,
    restaurants: restaurantSearchReducer,
    cart: cartReducer,
    coupon: couponReducer,
    checkout: checkoutReducer,
    confirmCart: confirmCartReducer,
    items:itemsReducer,
    restaurant: restaurantReducer,
    paymentgateways: paymentGatewayReducer,
    vivaWallet:vivaWalletReducer
});