import React from "react";
import Meta from "../../helpers/meta";


function MetaElement() {
return ( <Meta
    seotitle={localStorage.getItem("cartPageTitle")}
    seodescription={localStorage.getItem("seoMetaDescription")}
    ogtype="website"
    ogtitle={localStorage.getItem("seoOgTitle")}
    ogdescription={localStorage.getItem("seoOgDescription")}
    ogurl={window.location.href}
    twittertitle={localStorage.getItem("seoTwitterTitle")}
    twitterdescription={localStorage.getItem("seoTwitterDescription")}
/>);
}

export default MetaElement;