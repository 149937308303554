import {
    GET_TOKEN,
    MAKE_PAYMENT
} from "./actionTypes";


const initialState = {
    viva_wallet: null,
    payment:null
};



export default function(state = initialState, action) {
    switch (action.type) {
        case GET_TOKEN:
            return { ...state, viva_wallet: action.payload };
        case MAKE_PAYMENT:
            return { ...state, payment: action.payload };

        default:
            return state;
    }
}