import { GET_SETTINGS } from "./actionTypes";
import { GET_SETTINGS_URL } from "../../configs";
import Axios from "axios";

export const getSettings = () => async(dispatch) => {
    try{
        const {data}= await Axios.post(GET_SETTINGS_URL);
        return dispatch({ type: GET_SETTINGS, payload: data });
    }catch (err) {
        console.log(err)
    }

};
