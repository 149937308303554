import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import { Helmet } from "react-helmet";
import PaypalExpressBtn from "react-paypal-express-checkout";
import {PaystackButton} from "react-paystack";
import { RAZORPAY_PAYMENT_URL } from "../../../../configs";
import StripeCheckout from "react-stripe-checkout";
import { connect } from "react-redux";
import { formatPrice } from "../../../helpers/formatPrice";
import { getPaymentGateways } from "../../../../services/paymentgateways/actions";
import { placeOrder } from "../../../../services/checkout/actions";
import { updateUserInfo } from "../../../../services/user/actions";
import { calculateDistance } from "../../../helpers/calculateDistance";
import { calculateDistanceGoogle } from "../../../helpers/calculateDistanceGoogle";
import Axios from "axios";
import { GoogleApiWrapper } from "google-maps-react";
import { getRestaurantInfoById } from "../../../../services/items/actions";
import { getVivaWalletToken,makeTransaction } from "../../../../services/vivaWallet/actions";
import { generateNewBooking } from "../../../../services/orders/actions";

import { withRouter } from 'react-router-dom';
import {testUserOrderDetails,getApiOrderType} from "../../../helpers/generalActions";
import VivaWalletPayment from "./VivaWallet/VivaWalletPayment";
import  VivaWallet3DSecure from './VivaWallet/VivaWallet3dSecure';
import {validateBooking,updateLocalStorageWithBooking} from '../../../helpers/generalActions';
import  ConfirmationModal from  '../../Elements/ConfirmationModal';
class PaymentList extends Component {
    static contextTypes = {
        router: () => null,
    };

    state = {
        loading: true,
        stripe_opened: false,
        delivery_charges: 0.0,
        error: false,
        razorpay_opened: false,
        razorpay_success: false,
        canPayPartialWithWallet: false,
        walletChecked: false,
        canPayFullWithWallet: false,
        distance: 0,
        placeOrderError: false,
        errorMessage: "",
        payment_provider_id:null,
        toggleVivaWalletModal:false,
        toggleVivaWallet3d:false,
        toggleConfirmationModal:false,
        vivaWalletInstalments:[],
        vivaWalletChargeToken:null,
        vivaWalletAuthToken:'',
        user_payment_data:{}
    };

    componentDidMount() {


        const { user } = this.props;
        if(user.success){
            this.props.getVivaWalletToken(user.data.id);
        }

        if(!testUserOrderDetails()){
            this.props.history.push("/");
        }
        if (localStorage.getItem("activeRestaurant") !== null) {
            this.props.getRestaurantInfoById(localStorage.getItem("activeRestaurant")).then((response) => {
                if (response) {
                    if (response.payload.id) {
                        this.__doesRestaurantOperatesAtThisLocation(response.payload);
                    }
                }
            });
        }



        this.props.getPaymentGateways(this.props.user.data.auth_token,this.props.restaurant_info.id);

        if (user.success) {
            this.props.updateUserInfo(user.data.id, user.data.auth_token, null);
        }

        if (localStorage.getItem("userSelected") === "SELFPICKUP") {
            this.setState({ delivery_charges: 0.0 });
        } else {
            this.setState({ delivery_charges: this.props.restaurant_info.delivery_charges });
        }

        this.verifyAndGenerateBooking()
    }

    requestNewBooking=()=>{

        const {user,restaurant_info}=this.props;
        if(!user.success){
            this.setState({ placeOrderError: true, errorMessage: 'Sorry, something went wrong. This account seems invalid.'});
            return;
        }
        let guests_number=1;

        if(localStorage.getItem("userPreferredSelection") === "DINEIN"){
            guests_number=localStorage.getItem("guestNumber")

        }
        const location=JSON.parse(localStorage.getItem("userSetAddress"));
        const order_time=localStorage.getItem('userOrderTime');
        const order_type=getApiOrderType(localStorage.getItem("userPreferredSelection"));
        const asap=order_type==='delivery' ?localStorage.getItem("asap") : null;


        this.props.generateNewBooking(user,location,restaurant_info.id,guests_number,order_time,order_type,asap);
    }

    verifyAndGenerateBooking=()=>{

        const {coupon}=this.props;
       let storage_booking=JSON.parse(localStorage.getItem('booking'));
        if(!storage_booking && !storage_booking){

            this.requestNewBooking();
        }else   if(coupon.booking && storage_booking && coupon.booking.id>storage_booking.id && validateBooking(coupon.booking)){
          localStorage.setItem('booking',coupon.booking);

      }else if(!storage_booking || !validateBooking(storage_booking)){

          this.requestNewBooking();
      }

    }


    vivaWalletPaymentSetup=()=>{
        const baseUrl =process.env.REACT_APP_VIVA_WALLET_BASE_URL;
        const authToken=this.state.vivaWalletAuthToken;
        if(!authToken){
            console.log('No auth token set for viva wallet');
            return;
        }
        const binding=this;
        setTimeout(function () {
            window.VivaPayments.cards.setup({
                baseURL: baseUrl,
                authToken,
                cardHolderAuthOptions: {
                    cardHolderAuthPlaceholderId: 'threed-pane',
                    cardHolderAuthInitiated: function () {
                        binding.handleVivaWalletPayment();
                        binding.handleVivaWallet3dSecure();
                    },
                    cardHolderAuthFinished: function (response) {

                        binding.handleVivaWallet3dSecure();


                    }
                },
                installmentsHandler: function (response) {

                    if (!response.Error) {
                        if (parseInt(response.MaxInstallments) === 0){
                            return ;
                        }

                        for (let i = 1; i <= response.MaxInstallments; i++) {
                                console.log('instalments-->',response);
                        }
                    }
                    else {
                        alert(response.Error);
                    }
                }
            });
        },1000)

    }

    makeVivaWalletPayment=(user_payment_data)=>{
        let totalAmount = formatPrice(parseFloat(this.getTotalAfterCalculation()));
        const binding=this;
        totalAmount=parseFloat(totalAmount);
        window.VivaPayments.cards.requestToken({
            amount: totalAmount
        }).done(function (data) {

              binding.setState({user_payment_data})
            binding.setState({vivaWalletChargeToken:data.chargeToken})
            binding.handleConfirmationModal();
        }).fail(function (responseData) {
            console.log('error',responseData)
            alert('Invalid card credentials! Please try again.');
        });
    }

    componentDidUpdate(prevProps) {

        if(this.props.vivaWallet!==prevProps.vivaWallet){
            const {viva_wallet,payment}=this.props.vivaWallet;

            if(viva_wallet.success && viva_wallet.viva_wallet_token && !this.state.vivaWalletAuthToken){

                    this.setState({vivaWalletAuthToken:viva_wallet.viva_wallet_token},()=>this.vivaWalletPaymentSetup())
            }

            if(payment && payment.success){

                this.__placeOrder('viva wallet payment',this.state.payment_provider_id)
            }else if(payment && payment.success===false){
                this.setState({ placeOrderError: true, errorMessage: payment.message});
            }
        }
        if(this.props.orders!==prevProps.orders){

            if(this.props.orders.booking){

                updateLocalStorageWithBooking(this.props.orders.booking);
            }
        }
        const { paymentgateways } = this.props;
        if (paymentgateways !== prevProps.paymentgateways) {
            this.setState({ loading: false });
        }
        if (prevProps.checkout !== this.props.checkout) {
            //remove coupon
            if(!testUserOrderDetails()){
                this.props.history.push("/");
            };

            localStorage.removeItem("appliedCoupon");
            //redirect to running order page
            this.props.history.push("/running-order/" + this.props.checkout.data.id);
        }

        // console.log("Wallet Balance: ", nextProps.user.data.wallet_balance);
        // console.log("Cart Amount: ", parseFloat(this.getTotalAfterCalculation()));

        //if  > 0 then user can pay with wallet (Amount will be deducted)
        if (this.props.user.data.wallet_balance > 0) {
            // console.log("Can pay partial with wallet");
            this.setState({ canPayPartialWithWallet: true, canPayFullWithWallet: false });
        }

        if (this.props.user.data.wallet_balance >= parseFloat(this.getTotalAfterCalculation())) {
            // console.log("Can pay full with wallet");
            this.setState({ canPayFullWithWallet: true, canPayPartialWithWallet: false });
        }
    }

    __doesRestaurantOperatesAtThisLocation = (restaurant_info) => {
        //send user lat long to helper, check with the current restaurant lat long and setstate accordingly
        const { user } = this.props;
        if (user.success) {
            let self = this;

            if (localStorage.getItem("enGDMA") === "true") {
                this.props.handleProcessDistanceCalcLoading(true);
                calculateDistanceGoogle(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    user.data.default_address.longitude,
                    user.data.default_address.latitude,
                    this.props.google,
                    function(distance) {
                        if (localStorage.getItem("userSelected") === "DELIVERY") {
                            if (self.props.restaurant_info.delivery_charge_type === "DYNAMIC") {
                                self.setState({ distance: distance }, () => {
                                    //check if restaurant has dynamic delivery charge..
                                    self.calculateDynamicDeliveryCharge();
                                });
                            }
                            self.props.handleProcessDistanceCalcLoading(false);
                        }
                    }
                );
            } else {
                const distance = calculateDistance(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    user.data.default_address.longitude,
                    user.data.default_address.latitude
                );
                if (localStorage.getItem("userSelected") === "DELIVERY") {
                    if (this.props.restaurant_info.delivery_charge_type === "DYNAMIC") {
                        this.setState({ distance: distance }, () => {
                            //check if restaurant has dynamic delivery charge..
                            this.calculateDynamicDeliveryCharge();
                        });
                    }
                }
            }
        }
    };

    calculateDynamicDeliveryCharge = () => {
        const { restaurant_info } = this.props;

        const distanceFromUserToRestaurant = this.state.distance;
        // console.log("Distance from user to restaurant: " + distanceFromUserToRestaurant + " km");

        if (distanceFromUserToRestaurant > restaurant_info.base_delivery_distance) {
            const extraDistance = distanceFromUserToRestaurant - restaurant_info.base_delivery_distance;
            // console.log("Extra Distance: " + extraDistance + " km");

            const extraCharge =
                (extraDistance / restaurant_info.extra_delivery_distance) * restaurant_info.extra_delivery_charge;
            // console.log("Extra Charge: " + extraCharge);

            let dynamicDeliveryCharge = parseFloat(restaurant_info.base_delivery_charge) + parseFloat(extraCharge);
            if (localStorage.getItem("enDelChrRnd") === "true") {
                dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
            }

            // console.log("Total Charge: " + dynamicDeliveryCharge);
            this.setState({ delivery_charges: dynamicDeliveryCharge });
        } else {
            this.setState({ delivery_charges: restaurant_info.base_delivery_charge });
        }
    };

    /* Stripe */
    onOpened = (payment_provider_id) => {
        this.setState({ stripe_opened: true,
       payment_provider_id
        });
    };
    onToken = (payment_token) => {
        // const method = "STRIPE";

        this.__placeOrder(payment_token, this.state.payment_provider_id);
    };
    /* END Stripe */

    /* Paypal */
    onSuccess = (payment) => {
        const payment_token = "";
        const method = "PAYPAL";
        this.__placeOrder(payment_token, method);
    };

    onCancel = (data) => {
        console.log("Paypal Payment Canceled");
    };

    onError = (err) => {
        console.log("Error!");
    };
    /* END Paypal */

    /* PayStack */
    callback = (response) => {
        if (response.status === "success") {
            const payment_token = response.reference;
            const method = "PAYSTACK";
            this.__placeOrder(payment_token, method);
        } else {
            console.log(response);
        }
    };

    close = () => {
        console.log("PayStack Payment Closed");
    };

    getReference = () => {
        //you can put any unique reference implementation code here
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

        for (let i = 0; i < 15; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

        return text;
    };
    /* END PayStack */

    __placeOrder = (payment_token, payment_provider_id) => {
          const   booking=JSON.parse(localStorage.getItem('booking'));

        if(!booking){
            this.setState({ placeOrderError: true, errorMessage: 'Sorry, an error occurred. No booking available' });
        }
        // disable all button Onclick with pointer events
        let paymentgatewaysblock = document.getElementsByClassName("paymentGatewayBlock");
        for (let i = 0; i < paymentgatewaysblock.length; i++) {
            paymentgatewaysblock[i].classList.add("no-click");
        }

        const { user, cartProducts, coupon, cartTotal,restaurant_info, } = this.props;
        const range=localStorage.getItem('userRange');
        const order_time=localStorage.getItem('userOrderTime');
          let guests_number=1;

            if(localStorage.getItem("userPreferredSelection") === "DINEIN"){
                guests_number=localStorage.getItem("guestNumber")

            }
            const order_type=getApiOrderType(localStorage.getItem("userPreferredSelection"));
        const asap=order_type==='delivery' ?localStorage.getItem("asap") : null;

        const booking_id=booking.id;
        if (user.success) {
            if (localStorage.getItem("userSelected") === "SELFPICKUP") {
                this.props
                    .placeOrder(
                        user,
                        range,
                        order_time,
                        cartProducts,
                        coupon,
                        JSON.parse(localStorage.getItem("userSetAddress")),
                        localStorage.getItem("orderComment"),
                        cartTotal,
                        payment_provider_id,
                        payment_token,
                        2,
                        this.state.walletChecked,
                        this.state.distance,
                        restaurant_info.id,
                        guests_number,
                        order_type,
                        booking_id,
                        asap
                    )
                    .then((response) => {

                        if (response) {
                            if (!response.success) {
                                this.setState({ placeOrderError: true, errorMessage: response.message });
                            }
                        }
                    });
            } else {
                this.props
                    .placeOrder(
                        user,
                        range,
                        order_time,
                        cartProducts,
                        coupon,
                        JSON.parse(localStorage.getItem("userSetAddress")),
                        localStorage.getItem("orderComment"),
                        cartTotal,
                        payment_provider_id,
                        payment_token,
                        1,
                        this.state.walletChecked,
                        this.state.distance,
                        restaurant_info.id,
                        guests_number,
                        order_type,
                        booking_id,
                        asap
                    )
                    .then((response) => {

                        if (response) {
                            if (!response.success) {
                                this.setState({ placeOrderError: true, errorMessage: response.message });
                                this.resetPage();
                            }
                        }
                    });
            }

            //show progress bar
            const progressBar = document.getElementById("progressBar");
            progressBar.classList.remove("hidden");
            let progress = 0;
            var foo = setInterval(function() {
                if (progress > 100) {
                    clearInterval(foo);
                }
                progress = progress + 1;
                progressBar.style.width = progress + "%";
            }, 20);

            this.setState({ stripe_opened: false });
        }
    };

    resetPage = () => {
        const progressBar = document.getElementById("progressBar");
        progressBar.classList.add("hidden");
        setTimeout(() => {
            progressBar.style.width = "0%";
        }, 2200);

        let paymentgatewaysblock = document.getElementsByClassName("paymentGatewayBlock");
        for (let i = 0; i < paymentgatewaysblock.length; i++) {
            paymentgatewaysblock[i].classList.remove("no-click");
        }
    };
    // Calculating total with/without coupon/tax
    getTotalAfterCalculation = () => {
        const { coupon, restaurant_info, user } = this.props;
        const total = this.props.cartTotal.totalPrice;
        let calc = 0;
        if (coupon.code) {
            if (coupon.discount_type === "PERCENTAGE") {
                let percentage_discount = formatPrice((coupon.discount / 100) * parseFloat(total));
                if (coupon.max_discount) {
                    if (parseFloat(percentage_discount) >= coupon.max_discount) {
                        percentage_discount = coupon.max_discount;
                    }
                }
                coupon.appliedAmount = percentage_discount;
                calc = formatPrice(
                    formatPrice(
                        parseFloat(total) -
                        percentage_discount +
                        parseFloat(restaurant_info.restaurant_charges || 0.0) +
                        parseFloat(this.state.delivery_charges || 0.0)
                    )
                );
            } else {
                calc = formatPrice(
                    parseFloat(total) -
                    (parseFloat(coupon.discount) || 0.0) +
                    ((parseFloat(restaurant_info.restaurant_charges) || 0.0) +
                        (parseFloat(this.state.delivery_charges) || 0.0))
                );
            }
        } else {
            calc = formatPrice(
                parseFloat(total) +
                parseFloat(restaurant_info.restaurant_charges || 0.0) +
                parseFloat(this.state.delivery_charges || 0.0)
            );
        }
        if (localStorage.getItem("taxApplicable") === "true") {
            calc = formatPrice(
                parseFloat(
                    parseFloat(calc) + parseFloat(parseFloat(localStorage.getItem("taxPercentage")) / 100) * calc
                )
            );
            if (this.state.walletChecked && user.data.wallet_balance < calc) {
                calc = calc - user.data.wallet_balance;
            }
            return calc;
        } else {
            if (this.state.walletChecked && user.data.wallet_balance < calc) {
                calc = calc - user.data.wallet_balance;
            }
            return calc;
        }
    };

    /* Razorpay */
    __handleRazorPay = () => {
        let self = this;
        this.setState({ razorpay_opened: true });
        const totalAmount = formatPrice(parseFloat(this.getTotalAfterCalculation()));

        Axios.post(RAZORPAY_PAYMENT_URL, {
            totalAmount: totalAmount,
        })
            .then((res) => {
                // console.log(res.data.response.id);
                if (res.data.razorpay_success) {
                    const options = {
                        key: localStorage.getItem("razorpayKeyId"),
                        amount: totalAmount,
                        name: localStorage.getItem("storeName"),
                        currency: localStorage.getItem("currencyId"),
                        order_id: res.data.response.id,
                        handler(response) {
                            // console.log("Final Response", response);
                            self.setState({ razorpay_opened: false, razorpay_success: true });
                            const payment_token = "";
                            const method = "RAZORPAY";
                            self.__placeOrder(payment_token, method);
                        },
                        modal: {
                            ondismiss: function() {
                                console.log("closed");
                                self.setState({ razorpay_opened: false, razorpay_success: false });
                            },
                        },
                        prefill: {
                            name: this.props.user.data.name,
                            email: this.props.user.data.email,
                            contact: this.props.user.data.phone,
                        },
                    };
                    const rzp1 = new window.Razorpay(options);

                    rzp1.open();
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    confirmSaveVivaWalletCard=response=>{
        this.handleConfirmationModal();
        this.makeVivaWalletTransaction(response);
    }


    makeVivaWalletTransaction=response=>{
        if(!this.state.user_payment_data || !this.state.vivaWalletChargeToken){
            alert('Something went wrong on payment please try again!')
            return
        }
       const booking= JSON.parse(localStorage.getItem('booking'));
        if(!booking){
            alert('Something went wrong.No bookign available. Please try again!')
            return
        }
        const {user}=this.props;

        let user_payment_data={};

        if(parseInt(response)===1){
             user_payment_data=this.state.user_payment_data;
        }
        user_payment_data.user_id=user.data.id;
        user_payment_data.chargeToken=this.state.vivaWalletChargeToken;
        user_payment_data.save_payment_method=parseInt(response);
        user_payment_data.booking_id=booking.id;
        user_payment_data.total_amount=  formatPrice(parseFloat(this.getTotalAfterCalculation()));
        this.props.makeTransaction(user_payment_data);

    }
    /* END Razorpay */
    handleVivaWalletPayment=()=>{
        this.setState({toggleVivaWalletModal:!this.state.toggleVivaWalletModal})
    }

    triggerVivaWalletPayment=payment_provider_id=>{
        this.setState({payment_provider_id},()=>this.handleVivaWalletPayment())
    }
    handleVivaWallet3dSecure=()=>{
        this.setState({toggleVivaWallet3d:!this.state.toggleVivaWallet3d})
    }
    handleConfirmationModal=()=>{
        this.setState({toggleConfirmationModal:!this.state.toggleConfirmationModal})

    }
    render() {

        const client = {
            sandbox: localStorage.getItem("paypalSandboxKey"),
            production: localStorage.getItem("paypalProductionKey"),
        };
        return (
            <React.Fragment>
                {this.state.placeOrderError && (
                    <div className="auth-error ongoing-payment">
                        <div className="error-shake">{this.state.errorMessage}</div>
                    </div>
                )}

                {this.props.paymentgateways.some((gateway) => gateway.name === "Razorpay") && (
                    <Helmet>
                        <script src="https://checkout.razorpay.com/v1/checkout.js" />
                    </Helmet>
                )}
                {this.state.stripe_opened ||
                (this.state.razorpay_opened && (
                    <React.Fragment>
                        <div className="height-80 overlay-loading ongoing-payment-spin">
                            <div className="spin-load" />
                        </div>
                        <div className="auth-error ongoing-payment">
                            <div className="error-shake">{localStorage.getItem("checkoutPaymentInProcess")}</div>
                        </div>
                    </React.Fragment>
                ))}

                <div className="col-12 mb-50">
                    {this.state.loading ? (
                        <div className="row">
                            <div className="col-12">
                                <div className="block block-link-shadow text-left shadow-light">
                                    <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                        <ContentLoader
                                            height={70}
                                            width={window.innerWidth}
                                            speed={1.2}
                                            foregroundColor="#f3f3f3"
                                            backgroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="10" rx="0" ry="0" width="55" height="55" />
                                            <rect x="320" y="10" rx="0" ry="0" width="85" height="20" />
                                            <rect x="250" y="40" rx="0" ry="0" width="190" height="18" />
                                        </ContentLoader>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="block block-link-shadow text-left shadow-light">
                                    <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                        <ContentLoader
                                            height={70}
                                            width={window.innerWidth}
                                            speed={1.2}
                                            foregroundColor="#f3f3f3"
                                            backgroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="10" rx="0" ry="0" width="55" height="55" />
                                            <rect x="320" y="10" rx="0" ry="0" width="85" height="20" />
                                            <rect x="250" y="40" rx="0" ry="0" width="190" height="18" />
                                        </ContentLoader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="text-center">
                                <h3
                                    className="btn btn-lg btn-outline-secondary btn-square d-block bg-white"
                                    style={{ borderColor: "#eee" }}
                                >
                                    {localStorage.getItem("cartToPayText")}{" "}
                                    <span style={{ color: localStorage.getItem("storeColor") }}>
										{localStorage.getItem("currencySymbolAlign") === "left" &&
                                        localStorage.getItem("currencyFormat")}
                                        {formatPrice(parseFloat(this.getTotalAfterCalculation()))}
                                        {localStorage.getItem("currencySymbolAlign") === "right" &&
                                        localStorage.getItem("currencyFormat")}
									</span>
                                </h3>
                            </div>
                            <div className="row">
                                {this.state.canPayPartialWithWallet && (
                                    <React.Fragment>
                                        <div
                                            className="col-12"
                                            onClick={() => this.setState({ walletChecked: !this.state.walletChecked })}
                                        >
                                            <div className="block block-link-shadow text-left shadow-light">
                                                <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                    <div className="float-right mt-10">
                                                        <img
                                                            src={require("../../../../assets/img/various/wallet.png")}
                                                            alt={localStorage.getItem("walletName")}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <input
                                                        type="checkbox"
                                                        name="walletcheckbox"
                                                        defaultChecked={this.props.walletChecked}
                                                        className="wallet-checkbox"
                                                    />
                                                    <div className="font-size-h3 font-w600">
                                                        {this.state.walletChecked && (
                                                            <i
                                                                className="si si-check mr-2"
                                                                style={{
                                                                    color: localStorage.getItem("cartColorBg"),
                                                                    fontWeight: "900",
                                                                }}
                                                            />
                                                        )}
                                                        {localStorage.getItem("walletName")}:{" "}
                                                        <span style={{ color: localStorage.getItem("storeColor") }}>
															{localStorage.getItem("currencySymbolAlign") === "left" &&
                                                            localStorage.getItem("currencyFormat")}
                                                            {this.props.user.data.wallet_balance}
                                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                            localStorage.getItem("currencyFormat")}
														</span>
                                                    </div>
                                                    <div className="font-size-sm font-w600 text-muted">
                                                        {this.state.walletChecked ? (
                                                            <React.Fragment>
																<span
                                                                    style={{
                                                                        color: localStorage.getItem("storeColor"),
                                                                    }}
                                                                >
																	{" "}
                                                                    {localStorage.getItem("currencySymbolAlign") ===
                                                                    "left" &&
                                                                    localStorage.getItem("currencyFormat")}
                                                                    {this.props.user.data.wallet_balance}{" "}
                                                                    {localStorage.getItem("currencySymbolAlign") ===
                                                                    "right" &&
                                                                    localStorage.getItem("currencyFormat")}
																</span>{" "}
                                                                {localStorage.getItem("willbeDeductedText")}{" "}
                                                                {localStorage.getItem("currencySymbolAlign") ===
                                                                "left" && localStorage.getItem("currencyFormat")}
                                                                {this.props.user.data.wallet_balance}
                                                                {localStorage.getItem("currencySymbolAlign") ===
                                                                "right" && localStorage.getItem("currencyFormat")}
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
																<span>
																	{localStorage.getItem("payPartialWithWalletText")}
																</span>
                                                                <button
                                                                    className="btn btn-redeem mt-2"
                                                                    style={{
                                                                        color: localStorage.getItem("cartColorBg"),
                                                                        borderColor: localStorage.getItem(
                                                                            "cartColorBg"
                                                                        ),
                                                                    }}
                                                                >
                                                                    {localStorage.getItem("walletRedeemBtnText")}
                                                                </button>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-4" />
                                        </div>
                                    </React.Fragment>
                                )}
                                <hr />
                                <div className="col-12 text-center mb-0 mt-4">
                                    <h4 className="text-muted">{localStorage.getItem("checkoutPaymentListTitle")}</h4>
                                </div>
                                {this.state.canPayFullWithWallet && (
                                    <React.Fragment>
                                        <div
                                            className="col-12 paymentGatewayBlock"
                                            onClick={() => this.__placeOrder("", "WALLET")}
                                        >
                                            <p className="mb-1" />
                                            <div className="block block-link-shadow text-left shadow-light">
                                                <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                    <div className="float-right mt-10">
                                                        <img
                                                            src={require("../../../../assets/img/various/wallet.png")}
                                                            alt={localStorage.getItem("walletName")}
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="font-size-h3 font-w600">
                                                        {localStorage.getItem("walletName")}
                                                    </div>
                                                    <div className="font-size-sm font-w600 text-muted">
                                                        {localStorage.getItem("payFullWithWalletText")}
                                                        <br />
                                                        <span style={{ color: localStorage.getItem("storeColor") }}>
															{localStorage.getItem("currencySymbolAlign") === "left" &&
                                                            localStorage.getItem("currencyFormat")}
                                                            {parseFloat(this.getTotalAfterCalculation())}
                                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                            localStorage.getItem("currencyFormat")}
														</span>{" "}
                                                        {localStorage.getItem("willbeDeductedText")}{" "}
                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                        localStorage.getItem("currencyFormat")}
                                                        {this.props.user.data.wallet_balance}
                                                        {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                        localStorage.getItem("currencyFormat")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                )}
                                {this.props.paymentgateways.map((gateway) => (
                                    <React.Fragment key={gateway.id}>
                                        <div className="col-12 paymentGatewayBlock">
                                            {gateway.provider.toLowerCase() === "stripe" && (
                                                <StripeCheckout
                                                    stripeKey={localStorage.getItem("stripePublicKey")}
                                                    ComponentClass="div"
                                                    image={require("../../../../assets/img/logos/logo-min.png")}
                                                    locale="auto"
                                                    name={localStorage.getItem("storeName")}
                                                    email={this.props.user.data.email}
                                                    token={this.onToken}
                                                    opened={()=>this.onOpened(gateway.id)}
                                                    amount={parseFloat(this.getTotalAfterCalculation() * 100)}
                                                    currency={localStorage.getItem("currencyId")}
                                                    alipay={
                                                        localStorage.getItem("stripeAcceptAliPay") === "true"
                                                            ? true
                                                            : false
                                                    }
                                                    bitcoin={
                                                        localStorage.getItem("stripeAcceptBitCoin") === "true"
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    <div className="col-12 p-0">
                                                        <div className="block block-link-shadow text-left shadow-light">
                                                            <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                                <div className="float-right mt-10">
                                                                    <img
                                                                        src={require("../../../../assets/img/various/stripe.png")}
                                                                        alt={gateway.user_front_text}
                                                                        className="img-fluid"
                                                                    />
                                                                </div>
                                                                <div className="font-size-h3 font-w600">
                                                                    {localStorage.getItem("checkoutStripeText")}
                                                                    <div className="font-size-sm font-w600 text-muted">
                                                                        {localStorage.getItem("checkoutStripeSubText")}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </StripeCheckout>
                                            )}
                                        </div>
                                        {gateway.provider.toLowerCase()  === "viva_wallet" && (
                                            <div
                                                className="col-12 paymentGatewayBlock"
                                                onClick={() => this.triggerVivaWalletPayment(gateway.id)}
                                            >
                                                <div className="block block-link-shadow text-left shadow-light">
                                                    <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                        <div className="float-right mt-10">
                                                            <img
                                                                style={{width:'80px',height:'50px'}}
                                                                src={require("../../../../assets/img/various/viva_wallet_simple_logo.jpeg")}
                                                                alt={gateway.user_front_text}
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div className="font-size-h3 font-w600">
                                                            {localStorage.getItem("checkoutVivaWalletText")}
                                                        </div>
                                                        <div className="font-size-sm font-w600 text-muted">
                                                            {localStorage.getItem("checkoutVivaWalletSubText")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {gateway.payment_type.toLowerCase()  === "cash_on_spot" && (
                                            <div
                                                className="col-12 paymentGatewayBlock"
                                                onClick={() => this.__placeOrder("", gateway.id)}
                                            >
                                                <div className="block block-link-shadow text-left shadow-light">
                                                    <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                        <div className="float-right mt-10">
                                                            <img
                                                                src={require("../../../../assets/img/various/cod.png")}
                                                                alt={gateway.user_front_text}
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div className="font-size-h3 font-w600">
                                                            {localStorage.getItem("checkoutCodText")}
                                                        </div>
                                                        <div className="font-size-sm font-w600 text-muted">
                                                            {localStorage.getItem("checkoutCodSubText")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {gateway.name === "Razorpay" && (
                                            <div
                                                className="col-12 paymentGatewayBlock"
                                                onClick={() => this.__handleRazorPay()}
                                            >
                                                <div className="block block-link-shadow text-left shadow-light">
                                                    <div className="block-content block-content-full clearfix py-3 payment-select-block">
                                                        <div className="float-right mt-10">
                                                            <img
                                                                src={require("../../../../assets/img/various/razorpay.png")}
                                                                alt={gateway.name}
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                        <div className="font-size-h3 font-w600">
                                                            {localStorage.getItem("checkoutRazorpayText")}
                                                        </div>
                                                        <div className="font-size-sm font-w600 text-muted">
                                                            {localStorage.getItem("checkoutRazorpaySubText")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {gateway.provider.toLowerCase() === "paystack" && (
                                            <div className="col-12 mb-4 mt-4 paymentGatewayBlock">
                                                <PaystackButton
                                                    text={localStorage.getItem("paystackPayText")}
                                                    class="payButton"
                                                    callback={this.callback}
                                                    close={this.close}
                                                    disabled={false}
                                                    embed={false}
                                                    reference={this.getReference()}
                                                    email={this.props.user.data.email}
                                                    amount={parseInt(parseFloat(this.getTotalAfterCalculation() * 100))}
                                                    paystackkey={localStorage.getItem("paystackPublicKey")}
                                                    tag="button"
                                                    currency={localStorage.getItem("currencyId")}
                                                />
                                            </div>
                                        )}
                                        {gateway.provider.toLowerCase() === "paypal" && (
                                            <div className="col-12 paymentGatewayBlock">
                                                <PaypalExpressBtn
                                                    env={localStorage.getItem("paypalEnv")}
                                                    client={client}
                                                    currency={localStorage.getItem("currencyId")}
                                                    total={parseFloat(this.getTotalAfterCalculation())}
                                                    shipping={1}
                                                    onError={this.onError}
                                                    onSuccess={this.onSuccess}
                                                    onCancel={this.onCancel}
                                                    style={{
                                                        size: "responsive",
                                                        color: "silver",
                                                        shape: "rect",
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>

                        </React.Fragment>
                    )}
                    <VivaWalletPayment open={this.state.toggleVivaWalletModal} vivaWalletInstalments={this.state.vivaWalletInstalments} handleVivaWalletPayment={this.handleVivaWalletPayment} makeVivaWalletPayment={this.makeVivaWalletPayment}/>
                    <VivaWallet3DSecure open={this.state.toggleVivaWallet3d} handleVivaWallet3dSecure={this.handleVivaWallet3dSecure} />
                    <ConfirmationModal open={this.state.toggleConfirmationModal} handleConfirmationModal={this.handleConfirmationModal} confirmAction={this.confirmSaveVivaWalletCard} text={localStorage.getItem('saveCardConfirmation')}
                                       subtext={localStorage.getItem('saveCardConfirmationSubtext')}
                    icon={require("../../../../assets/img/icons/wallet.svg")}
                    />
                    <div  className={` ${(this.state.toggleVivaWallet3d ||this.state.toggleVivaWalletModal) ?  'modal-backdrop' : ''}`} />
                </div>

                <div className="progress push m-0 progress-transparent" style={{ height: "8px" }}>
                    <div
                        className="progress-bar progress-bar-striped progress-bar-animated hidden"
                        role="progressbar"
                        id="progressBar"
                        style={{
                            backgroundColor: localStorage.getItem("storeColor"),
                            width: "10%",
                        }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    addresses: state.addresses.addresses,
    cartProducts: state.cart.products,
    cartTotal: state.total.data,
    coupon: state.coupon.coupon,
    checkout: state.checkout.checkout,
    paymentgateways: state.paymentgateways.paymentgateways,
    restaurant_info: state.items.restaurant_info,
    range: state.restaurants.range,
    pickup_time: state.restaurants.pickup_time,
    vivaWallet: state.vivaWallet,
    orders:state.orders
});

export default connect(
    mapStateToProps,
    { getPaymentGateways, placeOrder, updateUserInfo, getRestaurantInfoById,getVivaWalletToken,makeTransaction,generateNewBooking }
)(
    GoogleApiWrapper({
        apiKey: localStorage.getItem("googleApiKey"),
    })(withRouter(PaymentList))
);
