import {
    CLEAR_SEARCH,
    SEARCH_RESTAURANTS,
    SET_PICKUP_DATE,
    SET_PICKUP_RANGE,
    SET_GUEST_NUMBER,
    SET_ASAP
} from "./actionTypes";
import {getDefaultBookingTime} from  '../../components/helpers/generalActions';
const initialState = {
    restaurants: [],
    range:localStorage.getItem('userRange') ? localStorage.getItem('userRange') : 0,
    pickup_time:getDefaultBookingTime(),
    guest_number:localStorage.getItem('guestNumber')? localStorage.getItem('guestNumber') : null,
    asap:localStorage.getItem('asap')? localStorage.getItem('asap') : null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case SEARCH_RESTAURANTS:
            return { ...state, restaurants: action.payload };

        case CLEAR_SEARCH:
            return { ...state, restaurants: action.payload };
        case SET_PICKUP_DATE:
            return { ...state, pickup_time: action.payload };
        case SET_PICKUP_RANGE:
            return { ...state, range: action.payload };
        case SET_GUEST_NUMBER:
            return { ...state, guest_number: action.payload };
        case SET_ASAP:
            return { ...state, asap: action.payload };
        default:
            return state;
    }
}
