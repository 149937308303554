import React, { Component } from "react";

import { checkUserRunningOrder, updateUserInfo } from "../../../services/user/actions";

import BackWithSearch from "../../Mobile/Elements/BackWithSearch";
import BillDetails from "./BillDetails";
import CartCheckoutBlock from "./CartCheckoutBlock";
import CartItems from "./CartItems";
import Coupon from "./Coupon";
import DelayLink from "../../helpers/delayLink";
import Footer from "../Footer";
import Ink from "react-ink";
import Meta from "../../helpers/meta";
import MetaElement from '../Elements/Meta';
import OrderComment from "./OrderComment";
import { Redirect } from "react-router";
import RestaurantInfoCart from "./RestaurantInfoCart";
import { calculateDistance } from "../../helpers/calculateDistance";
import { calculateDistanceGoogle } from "../../helpers/calculateDistanceGoogle";
import { connect } from "react-redux";
import { getRestaurantInfoById } from "../../../services/items/actions";
import { updateCart } from "../../../services/total/actions";
import { formatPrice } from "../../helpers/formatPrice";
import { addProduct } from "../../../services/cart/actions";
import { checkCartItemsAvailability } from "../../../services/confirmCart/actions";
import { getRestaurantDeliveryCharges } from "../../../services/restaurant/actions";

import { GoogleApiWrapper } from "google-maps-react";
import Loading from "../../helpers/loading";
import { withRouter } from 'react-router-dom';
import {getApiOrderType} from "../../helpers/generalActions";

class Cart extends Component {
    static contextTypes = {
        router: () => null,
    };

    state = {
        loading: false,
        alreadyRunningOrders: false,
        is_operational_loading: true,
        is_operational: true,
        distance: 0,
        is_active: false,
        min_order_satisfied: false,
        process_cart_loading: false,
        is_all_items_available: false,
        process_distance_calc_loading: false,
        restaurant_charges:0,
        delivery_charges:0,
        application_fee:0,
        placeOrderError:null,
        errorMessage:null,
        callRestaurantDeliveryCharges:false,
        user_default_address:null
    };

    componentDidMount() {
        if (this.props.cartProducts.length) {
            document.getElementsByTagName("body")[0].classList.add("bg-grey");
            this.checkForItemsAvailability();
        }
        if (localStorage.getItem("activeRestaurant") !== null) {
            this.props.getRestaurantInfoById(localStorage.getItem("activeRestaurant")).then((response) => {

                if (response) {
                    if (response.payload.id) {
                        this.__doesRestaurantOperatesAtThisLocation(response.payload);
                    }
                }
            });


        }

        const { user } = this.props;
        if (user.success) {
            this.props.checkUserRunningOrder(user.data.id, user.data.auth_token);
            this.props.updateUserInfo(user.data.id, user.data.auth_token).then((updatedUser) => {
                if (typeof updatedUser !== "undefined") {
                    const userSetAddress = {
                        lat: updatedUser.payload.data.default_address.latitude,
                        lng: updatedUser.payload.data.default_address.longitude,
                        address: updatedUser.payload.data.default_address.address,
                        house: updatedUser.payload.data.default_address.house,
                        tag: updatedUser.payload.data.default_address.tag,
                        postcode: updatedUser.payload.data.default_address.postcode,
                    };
                    this.setState({user_default_address:updatedUser.payload.data.default_address});
                    localStorage.setItem("userSetAddress", JSON.stringify(userSetAddress));
                    const booking=JSON.parse(localStorage.getItem("booking"));
                    if(booking){
                        this.setRestaurantDeliveryCharges(updatedUser.payload.data.default_address,booking.id)
                    }else{
                        this.setState({callRestaurantDeliveryCharges:true})
                    }

                } else {
                    console.warn("Failed to fetch update user info... Solution: Reload Page");
                }

            });


        } else {
            this.setState({ alreadyRunningOrders: false });
        }
    }

    setRestaurantDeliveryCharges=(default_address,booking_id)=>{
        console.log(123);
       const order_type=getApiOrderType(localStorage.getItem("userPreferredSelection"));

        this.props.getRestaurantDeliveryCharges(localStorage.getItem("activeRestaurant"),default_address,order_type,booking_id).then((response) => {

            response=response.payload

            if(!response || response.success===false){
                this.setState({
                    is_operational: false,
                    is_operational_loading: false,
                });
                return
            }
            this.setState({
                restaurant_charges:response.restaurant_charges,
                delivery_charges:response.delivery_charges,
                application_fee:response.application_fee
            })
        });
    }


    componentDidUpdate(prevProps) {

        if(this.props.restaurant_info!==prevProps.restaurant_info)
        {
            if (this.props.restaurant_info.id) {
                this.__isRestaurantActive(this.props.restaurant_info);

                this.__checkMinOrderSatisfied(this.props.restaurant_info, this.props.cartTotal);
            }

            if (this.props.running_order) {
                this.setState({alreadyRunningOrders: true});
            }
        }
        if(this.props.orders!==prevProps.orders){
            if(this.props.orders.booking && this.state.callRestaurantDeliveryCharges){
                console.log('callRestaurantDeliveryCharges')
                this.setRestaurantDeliveryCharges(this.state.user_default_address,this.props.orders.booking.id)
            }
        }
    }
    addProductQuantity = (product) => {
        const { cartProducts, updateCart } = this.props;
        let productAlreadyInCart = false;

        cartProducts.forEach((cp) => {
            if (cp.id === product.id) {
                if (JSON.stringify(cp.selectedaddons) === JSON.stringify(product.selectedaddons)) {
                    cp.quantity += 1;
                    productAlreadyInCart = true;
                }
            }
        });

        if (!productAlreadyInCart) {
            cartProducts.push(product);
        }

        updateCart(cartProducts);
    };

    removeProductQuantity = (product) => {
        const { cartProducts, updateCart } = this.props;

        const index = cartProducts.findIndex(
            (p) => p.id === product.id && JSON.stringify(p) === JSON.stringify(product)
        );
        //if product is in the cart then index will be greater than 0
        if (index >= 0) {
            cartProducts.forEach((cp) => {
                if (cp.id === product.id) {
                    if (JSON.stringify(cp) === JSON.stringify(product)) {
                        if (cp.quantity === 1) {
                            //if quantity is 1 then remove product from cart
                            cartProducts.splice(index, 1);
                        } else {
                            //else decrement the quantity by 1
                            cp.quantity -= 1;
                        }
                    }
                }
            });

            updateCart(cartProducts);
        }
    };

    removeProduct = (product) => {
        // console.log(product);
        // console.log(product.id);

        const { cartProducts, updateCart } = this.props;
        const index = cartProducts.findIndex((cp) => cp.id === product.id);

        // console.log(index);
        cartProducts.splice(index, 1);
        // console.log(cartProducts);
        updateCart(cartProducts);

        this.checkForItemsAvailability();
    };

    __doesRestaurantOperatesAtThisLocation = (restaurant_info) => {
        //send user lat long to helper, check with the current restaurant lat long and setstate accordingly
        const { user } = this.props;
        if (user.success) {
            var distance = 0;
            let self = this;
            if (localStorage.getItem("enGDMA") === "true") {
                this.setState({ process_distance_calc_loading: true });
                distance = calculateDistanceGoogle(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    user.data.default_address.longitude,
                    user.data.default_address.latitude,
                    this.props.google,
                    function(distance) {
                        console.log("Distance:", distance);
                        self.setState({ distance: distance, process_distance_calc_loading: false });
                    }
                );
            } else {
                const distance = calculateDistance(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    user.data.default_address.longitude,
                    user.data.default_address.latitude
                );
                this.setState({ distance: distance });
            }


            if (this.state.distance <= restaurant_info.delivery_radius) {

                this.setState({
                    is_operational: true,
                    is_operational_loading: false,
                });
            } else {
                this.setState({
                    is_operational: false,
                    is_operational_loading: false,
                });
            }
        } else {
            //if Google Distance Matrix API is enabled
            let self = this;
            if (localStorage.getItem("enGDMA") === "true") {
                distance = calculateDistanceGoogle(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    JSON.parse(localStorage.getItem("userSetAddress")).lng,
                    JSON.parse(localStorage.getItem("userSetAddress")).lat,
                    this.props.google,
                    function(distance) {
                        console.log(distance);
                        self.setState({ distance: distance });
                    }
                );
            } else {
                distance = calculateDistance(
                    restaurant_info.longitude,
                    restaurant_info.latitude,
                    JSON.parse(localStorage.getItem("userSetAddress")).lng,
                    JSON.parse(localStorage.getItem("userSetAddress")).lat
                );
                this.setState({ distance: distance });
            }


            // console.log(distance);
            if (this.state.distance <= restaurant_info.delivery_radius) {

                this.setState({
                    is_operational: true,
                    is_operational_loading: false,
                });
            } else {
                this.setState({
                    is_operational: false,
                    is_operational_loading: false,
                });
            }
        }
    };

    __isRestaurantActive = (restaurant_info) => {
        if (restaurant_info.active) {
            this.setState({
                is_active: true,
            });
        }
    };

    __checkMinOrderSatisfied = (restaurant_info, cartTotal) => {
        if (restaurant_info.min_order_price && restaurant_info.min_order_price > 0) {

            //if not null, then check the min order price with the order total
            const totalPrice = parseFloat(formatPrice(cartTotal.totalPrice));
            const minOrderPrice = parseFloat(formatPrice(restaurant_info.min_order_price));
            if (totalPrice >= minOrderPrice) {
                // console.log("Order Can Be Placed", totalPrice + " -- " + minOrderPrice);
                this.setState({ min_order_satisfied: true });
            } else {
                // console.log("Order CANNOT Be Placed", totalPrice + " -- " + minOrderPrice);
                this.setState({ min_order_satisfied: false });
            }
        } else {

            // if null, then set to satisfied to true...
            // console.log("Min order price is not set");
            this.setState({ min_order_satisfied: true });
        }
    };

    handleProcessCartLoading = (value) => {
        this.setState({ process_cart_loading: value });
    };

    checkForItemsAvailability = () => {
        const { checkCartItemsAvailability, cartProducts, addProduct, updateCart } = this.props;
        this.handleProcessCartLoading(true);
        checkCartItemsAvailability(cartProducts).then((response) => {
            this.handleProcessCartLoading(false);
            this.setState({ process_cart_loading: false });

            if (response.length) {
                //get inactive items and mark as is_active 0
                cartProducts
                    .filter(({ id }) => response.includes(id))
                    .map((item) => {
                        item.is_active = 0;
                        addProduct(item);
                        return item;
                    });
                this.handleItemsAvailability(false);
            } else {
                //if response length is 0 that means all items in cart are available, make all active
                cartProducts.map((item) => {
                    item.is_active = 1;
                    addProduct(item);
                    return item;
                });
                this.handleItemsAvailability(true);
            }
            updateCart(this.props.cartProducts);
        });
    };
    handleItemsAvailability = (value) => {
        this.setState({ is_all_items_available: value });
    };
    componentWillUnmount() {
        document.getElementsByTagName("body")[0].classList.remove("bg-grey");
    }

    handleError=(show,errorMessage)=>{
        this.setState({ placeOrderError: show, errorMessage});
    }
    render() {


        // console.log("IS ITEMS AVAILABLE", this.state.is_all_items_available);

        // console.log("MIN ORDER SATISFIED?", this.state.min_order_satisfied);

        if (window.innerWidth > 768) {
            return <Redirect to="/" />;
        }
        if (localStorage.getItem("storeColor") === null) {
            return <Redirect to={"/"} />;
        }
        if (!this.props.cartProducts.length) {
            document.getElementsByTagName("body")[0].classList.remove("bg-grey");
        }
        const { cartTotal, cartProducts, restaurant_info } = this.props;
        return (
            <React.Fragment>
                {this.state.placeOrderError && (
                    <div className="auth-error ongoing-payment">
                        <div className="error-shake">{this.state.errorMessage}</div>
                    </div>
                )}
                <MetaElement/>

                {this.state.process_cart_loading && (
                    <div className="height-100 overlay-loading ongoing-payment-spin">
                        <div className="spin-load" />
                    </div>
                )}
                {this.state.process_distance_calc_loading && (
                    <div className="height-100 overlay-loading ongoing-payment-spin">
                        <div className="spin-load" />
                    </div>
                )}

                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <BackWithSearch
                            boxshadow={true}
                            has_title={true}
                            title={localStorage.getItem("cartPageTitle")}
                            disable_search={true}
                        />
                        {cartProducts.length ? (
                            <React.Fragment>
                                <div>
                                    <RestaurantInfoCart restaurant={restaurant_info} />
                                    <div className="block-content block-content-full bg-white pt-10 pb-5">
                                        <h2 className="item-text mb-10">
                                            {localStorage.getItem("cartItemsInCartText")}
                                        </h2>
                                        {cartProducts.map((item, index) => (
                                            <CartItems
                                                item={item}
                                                addProductQuantity={this.addProductQuantity}
                                                removeProductQuantity={this.removeProductQuantity}
                                                removeProduct={this.removeProduct}
                                                key={item.name + item.id + index}
                                            />
                                        ))}
                                    </div>
                                    <OrderComment />
                                </div>

                                <div>
                                    <Coupon subtotal={this.props.cartTotal.totalPrice} />
                                    {this.state.alreadyRunningOrders && (
                                        <div className="auth-error ongoing-order-notify">
                                            <DelayLink to="/my-orders" delay={250} className="ml-2">
                                                {localStorage.getItem("ongoingOrderMsg")}{" "}
                                                <i
                                                    className="si si-arrow-right ml-1"
                                                    style={{
                                                        fontSize: "0.7rem",
                                                    }}
                                                />
                                                <Ink duration="500" />
                                            </DelayLink>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <BillDetails restaurant_charges={this.state.restaurant_charges} application_fee={this.state.application_fee} delivery_charges={this.state.delivery_charges} total={cartTotal.totalPrice} distance={this.state.distance} />
                                </div>

                                {this.state.is_operational_loading ? (
                                    <img
                                        src={require("../../../assets/img/various/spinner.svg")}
                                        className="location-loading-spinner"
                                        alt="loading"
                                        style={{ marginTop: "-1rem" }}
                                    />
                                ) : (
                                    <React.Fragment>
                                        {this.state.is_active ? (
                                            <React.Fragment>
                                                {this.state.min_order_satisfied ? (
                                                    <React.Fragment>
                                                        {this.state.is_all_items_available && (
                                                            <CartCheckoutBlock

                                                                handleError={this.handleError}
                                                                restaurant_id={this.props.restaurant_info.id}
                                                                cart_page={this.props.location.pathname}
                                                                is_operational_loading={
                                                                    this.state.is_operational_loading
                                                                }
                                                                is_operational={this.state.is_operational}
                                                                handleProcessCartLoading={this.handleProcessCartLoading}
                                                                checkForItemsAvailability={
                                                                    this.checkForItemsAvailability
                                                                }
                                                                handleItemsAvailability={this.handleItemsAvailability}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="auth-error no-click">
                                                        <div className="error-shake">
                                                            {localStorage.getItem("restaurantMinOrderMessage")}{" "}
                                                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                            localStorage.getItem("currencyFormat")}
                                                            {this.props.restaurant_info.min_order_price}
                                                            {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                            localStorage.getItem("currencyFormat")}
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <div className="auth-error no-click">
                                                <div className="error-shake">
                                                    {this.props.restaurant_info && this.props.restaurant_info.name} :{" "}
                                                    {localStorage.getItem("notAcceptingOrdersMsg")}
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <div className="bg-white cart-empty-block">
                                <img
                                    className="cart-empty-img"
                                    src={require("../../../assets/img/cart-empty.png")}
                                    alt={localStorage.getItem("cartEmptyText")}
                                />
                                <h2 className="cart-empty-text mt-50 text-center">
                                    {localStorage.getItem("cartEmptyText")}
                                </h2>
                                {this.state.alreadyRunningOrders && (
                                    <div
                                        className="auth-error ongoing-order-notify"
                                        style={{
                                            position: "fixed",
                                            bottom: "4.5rem",
                                        }}
                                    >
                                        <DelayLink to="/my-orders" delay={250} className="ml-2">
                                            {localStorage.getItem("ongoingOrderMsg")}{" "}
                                            <i className="si si-arrow-right ml-1" style={{ fontSize: "0.7rem" }} />
                                            <Ink duration="500" />
                                        </DelayLink>
                                    </div>
                                )}
                                <Footer active_cart={true} />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    restaurant_info: state.items.restaurant_info,
    cartProducts: state.cart.products,
    cartTotal: state.total.data,
    user: state.user.user,
    running_order: state.user.running_order,
    restaurant: state.restaurant,
    orders:state.orders
});

export default GoogleApiWrapper({
    apiKey: localStorage.getItem("googleApiKey"),
    LoadingContainer: Loading,
})(
    connect(
        mapStateToProps,
        {
            checkUserRunningOrder,
            updateCart,
            getRestaurantInfoById,
            updateUserInfo,
            addProduct,
            checkCartItemsAvailability,
            getRestaurantDeliveryCharges
        }
    )(withRouter(Cart))
);
