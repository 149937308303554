import { GET_ALL_LANGUAGES, GET_SINGLE_LANGUAGE_DATA } from "./actionTypes";
import Axios from "axios";
import { GET_ALL_LANGUAGES_URL, GET_SINGLE_LANGUAGE_DATA_URL } from "../../configs";


export const getAllLanguages= ()=>async(dispatch)=>{
    try{
        let {data}=await Axios.post(GET_ALL_LANGUAGES_URL)
                return dispatch({ type: GET_ALL_LANGUAGES, payload: data });

    }catch ( err) {
        console.log(err);

    }
}

export const getSingleLanguageData= (id)=>async(dispatch)=>{
    try{
        let {data}= await Axios.post(GET_SINGLE_LANGUAGE_DATA_URL,{id})
        return dispatch({ type: GET_SINGLE_LANGUAGE_DATA, payload: data });

    }catch ( err) {
        console.log(err);

    }
}