import React, { Component } from "react";
import { verifyMenuItemsMenuGroups,showQuantity} from "../../../helpers/generalActions";
import { showPrice } from "../../../helpers/formatPrice";

import Collapsible from "react-collapsible";

class ChildItems extends Component {
    state = {
        open: false,
        menuGroupClicked:false,
        next_level:''
    };

    componentDidMount() {
        let level=this.props.nesting_level;
        this.setState({next_level:++level})
        // this.testForAccessToChildItems();
        this.openMenuGroup();
    }

    testForAccessToChildItems=()=> {

        return !(!this.props.parent_menu_item.quantity || this.props.parent_menu_item.quantity === 0)
    }


    openMenuGroup=()=>{
        if(!this.testForAccessToChildItems()){
            return  ;
        }
        this.setState({menuGroupClicked:!this.state.menuGroupClicked})
       // this.testForAccessToChildItems();
    }

    viewMenuGroup=( )=>{
        if(!this.testForAccessToChildItems()){
           return  false;
        }
        return this.state.menuGroupClicked;
    }

    render(){

        const {parent_menu_item}=this.props;
        return(
            <React.Fragment>

                {this.props.menu_items_menu_groups && this.props.menu_items_menu_groups.map((menu_group,index)=>(

                    <Collapsible key={menu_group.id+Math.random()}
                        trigger={menu_group.name}
                        open={
                            this.viewMenuGroup()
                        }
                                 handleTriggerClick={()=>this.openMenuGroup()}

                    >

                    <div key={menu_group.id+Math.random()}  style={{position:'relative'}}>
                        {(menu_group.pivot.minQuantity===1 && menu_group.pivot.maxQuantity===1) &&
                        <button className='btn uncheck-btn uncheck-btn-child' onClick={()=>this.props.uncheckMenuGroup(menu_group.id,this.props.nesting_level)}>{localStorage.getItem('uncheckMenuItemsBtn')}</button>
                        }
                        <React.Fragment>
                            <p className="addon-category-name">{menu_group.name}</p>
                            {menu_group.menu_items.length && (
                                <React.Fragment>
                                    {menu_group.menu_items.map((menu_item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="form-group addon-list">
                                                {(menu_group.pivot.minQuantity===1 && menu_group.pivot.maxQuantity===1) ?

                                                    <input
                                                        type= "radio"
                                                         className="pull-right " //magic-radio
                                                        onChange={()=>this.props.selectMenuItem(menu_item.id,menu_group.id,'add',this.props.nesting_level)}
                                                        checked={menu_item.quantity ===1 }
                                                         disabled={!this.props.testEnabledButton(parent_menu_item,menu_group)}
                                                    /> : <div className='item-actions pull-right pb-0 quantity-btns-container'><button className="btn-custom-quantity btn btn-sm ripple-btn" onClick={()=>this.props.selectMenuItem(menu_item.id,menu_group.id,'subtract',this.props.nesting_level)}>-</button>
                                                        <span className='quantity-container'>{showQuantity(menu_item.quantity)}</span>
                                                        <button className="btn-custom-quantity btn btn-sm ripple-btn" onClick={()=>this.props.selectMenuItem(menu_item.id,menu_group.id,'add',this.props.nesting_level)}  disabled={!this.props.testEnabledButton(parent_menu_item,menu_group)}>+</button>
                                                    </div>}

                                                <label className="text addon-label " htmlFor={menu_item.name}>
                                                    <span className='half-width'>{menu_item.name}{" "}
                                                    {localStorage.getItem("hidePriceWhenZero") === "true" &&
                                                    menu_item.price === "0.00" ? null : (
                                                        <React.Fragment>
                                                           <b> {showPrice(menu_item.price)}</b>
                                                        </React.Fragment>
                                                    )}</span>
                                                </label>
                                            </div>
                                            {(menu_item.menu_items_menu_groups && menu_item.menu_items_menu_groups.length>0 && verifyMenuItemsMenuGroups( menu_item.menu_items_menu_groups)) &&

                                            <ChildItems uncheckMenuGroup={this.props.uncheckMenuGroup}  menu_items_menu_groups={menu_item.menu_items_menu_groups} nesting_level={this.state.next_level} testEnabledButton={this.props.testEnabledButton} parent_menu_item={menu_item} selectMenuItem={this.props.selectMenuItem} />


                                            }
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </div>
                    </Collapsible>)
                            )}
            </React.Fragment>
        )
    }
}

export default ChildItems;