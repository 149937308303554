import React, { Component } from "react";

import Ink from "react-ink";

import Modal from "react-responsive-modal";
import ChildItems from "../ChildItems";
import {verifyMenuItemsMenuGroups,showQuantity,generateUuid} from '../../../helpers/generalActions'
import {updateMenuItem,updateItemPower,maxPowerReached,testGroupPower,testAllGroupsPower,uncheckUserMenuGroup} from '../../../helpers/menuItemsActions'
import { showPrice } from "../../../helpers/formatPrice";

import {connect} from "react-redux";
import {updateProduct} from "../../../../services/cart/actions";

class Customization extends Component {
    state = {
        open: false,
        maxQuantityGroup:null,
        minQuantityGroup:null,
        generalPower:0,
        menu_items_menu_groups:[],
        conditionsMet:false
    };



    handlePopupOpen = () => {
        this.setState({ open: true });
    };
    handlePopupClose = () => {
        this.setState({ open: false });
        this.props.forceUpdate();
    };

    componentDidMount() {
        this.testMenuItemConditions();
    }

    __processProduct=product=>{
        this.props.addProduct(product);
    }
    testEnabledButton=(parent_menu_item,menu_group)=>{

        if(!parent_menu_item.quantity || parent_menu_item.quantity===0){
            return  false;
        }
        if(maxPowerReached(parent_menu_item) && (menu_group.pivot.maxQuantity!==1)){
            return  false;
        }
        return !testGroupPower(menu_group);
    }

    selectMenuItem=(menu_item_id,menu_group_id,action,level)=>{
         const {product}=this.props;
         if(level===1){
             updateItemPower(product,action);
         }

        product.menu_items_menu_groups= updateMenuItem(product.menu_items_menu_groups,menu_item_id,menu_group_id,action,level);
         this.props.updateProduct(product);
        this.testMenuItemConditions();

    }

    testMenuItemConditions=()=>{

          if(((!this.props.product.power ||this.props.product.power===0) && this.props.product.minQuantityGroup===0) ||(this.props.product.power>=this.props.product.minQuantityGroup && (this.props.product.power<=this.props.product.maxQuantityGroup || this.props.product.maxQuantityGroup===0) && testAllGroupsPower(this.props.product.menu_items_menu_groups))){
              this.setState({conditionsMet:true})
          }else{
              this.setState({conditionsMet:false})

          }
    }

    uncheckMenuGroup=(menu_group_id,level)=>{
        const {product}=this.props;

        product.menu_items_menu_groups= uncheckUserMenuGroup(product.menu_items_menu_groups,menu_group_id,level);
        if(level===1){
            updateItemPower(product,'subtract');
        }
         this.props.updateProduct(product);
         this.testMenuItemConditions();

    }

    render() {
        const { product } = this.props;


        return (
            <React.Fragment     >
                <button
                    type="button"
                    className="btn btn-add-remove"
                    style={{
                        color: localStorage.getItem("cartColor-bg"),
                    }}
                    onClick={this.handlePopupOpen}
                >
                    <span className="btn-inc">+</span>
                    <Ink duration="500" />
                </button>
                <Modal open={this.state.open} onClose={this.handlePopupClose} closeIconSize={32} styles={{modal:{display:'block',width:'100%',height:'100%'}}}>
                    <h3>{localStorage.getItem("customizationHeading")}</h3>
                    <div
                        style={{
                            maxHeight:'85%',
                            marginTop: "2rem",
                            textAlign: "left",
                            overflowY:'auto',

                        }}
                    >

                        {this.props.product.menu_items_menu_groups && this.props.product.menu_items_menu_groups.map((menu_group) => (
                            <div key={menu_group.id+Math.random()} className="addon-category-block" style={{overflowY:'auto', maxHeight:'50%'}}>

                                <React.Fragment>
                                    <p className="addon-category-name">{menu_group.name}</p>
                                    {menu_group.menu_items.length && (
                                        <React.Fragment>
                                            {(menu_group.pivot.minQuantity===1 && menu_group.pivot.maxQuantity===1) &&
                                            <button className='btn uncheck-btn ' onClick={()=>this.uncheckMenuGroup(menu_group.id,1)}>uncheck</button>
                                            }

                                            {menu_group.menu_items.map((menu_item, index) => (
                                                <div key={index+menu_item.id}>

                                                    <div className="form-group addon-list" >
                                                        {(menu_group.pivot.minQuantity===1 && menu_group.pivot.maxQuantity===1) ?

                                                        <input
                                                            type= "radio"

                                                            className="pull-right" //magic-radio
                                                            onChange={()=>this.selectMenuItem(menu_item.id,menu_group.id,'add',1)}
                                                            disabled={!this.testEnabledButton(product,menu_group)}
                                                            checked={menu_item.quantity ===1 }
                                                        /> : <div className='item-actions pull-right pb-0 quantity-btns-container'><button className="btn-custom-quantity btn btn-sm ripple-btn"   onClick={()=>this.selectMenuItem(menu_item.id,menu_group.id,'subtract',1)} >-</button>
                                                        <span className='quantity-container'>{showQuantity(menu_item.quantity)}</span>
                                                                <button className="btn-custom-quantity btn btn-sm ripple-btn"   onClick={()=>this.selectMenuItem(menu_item.id,menu_group.id,'add',1)} disabled={!this.testEnabledButton(product,menu_group)}>+</button>
                                                        </div>}


                                                        <label className="text addon-label " htmlFor={menu_item.name} >
                                                            <span className='half-width'>{menu_item.name}{" "}
                                                            {localStorage.getItem("hidePriceWhenZero") === "true" &&
                                                            menu_item.price === "0.00" ? null : (
                                                                <React.Fragment>
                                                                   <b> {showPrice(menu_item.price)}</b>
                                                                </React.Fragment>
                                                            )}</span>
                                                        </label>


                                                    </div>


                                            {(menu_item.menu_items_menu_groups && menu_item.menu_items_menu_groups.length>0 && verifyMenuItemsMenuGroups( menu_item.menu_items_menu_groups)) ?

                                            <ChildItems uncheckMenuGroup={this.uncheckMenuGroup} menu_items_menu_groups={menu_item.menu_items_menu_groups} nesting_level={2} testEnabledButton={this.testEnabledButton} parent_menu_item={menu_item} selectMenuItem={this.selectMenuItem}/>
                                            :<hr />

                                            }
                                                </div>
                                                ))}
                                        </React.Fragment>
                                    )}

                                </React.Fragment>
                            </div>
                        ))}
                        <button
                            disabled={!this.state.conditionsMet}
                            className={`btn btn-lg btn-customization-done  ${!this.state.conditionsMet && 'disable-btn'}`}
                            onClick={() => {
                                this.__processProduct(product);
                                this.handlePopupClose();
                            }}
                            style={{
                                backgroundColor: localStorage.getItem("cartColorBg"),
                                color: localStorage.getItem("cartColorText"),
                            }}
                        >
                            {localStorage.getItem("customizationDoneBtnText")}
                        </button>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
});

const setUniqueUuidOnSubItems=menu_items_menu_groups=>{
    return menu_items_menu_groups.map(menu_group=>{
        menu_group.menu_items= menu_group.menu_items.map(menu_item=>{
            menu_item.custom_id=generateUuid();
            if(menu_item.menu_items_menu_groups && menu_item.menu_items_menu_groups.length>0){
                menu_item.menu_items_menu_groups=setUniqueUuidOnSubItems(menu_item.menu_items_menu_groups);
            }
            return menu_item;
        })
        return menu_group;
    })
}
export default connect(
    mapStateToProps,
    { updateProduct }
)(Customization);