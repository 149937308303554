import React, { Component } from "react";

import DelayLink from "../../../../helpers/delayLink";
import Ink from "react-ink";
import Moment from "react-moment";

import { formatPrice } from "../../../../helpers/formatPrice";
import OrderCancelPopup from "./OrderCancelPopup";

class OrderList extends Component {
    componentDidMount() {
        document.getElementsByTagName("body")[0].classList.add("bg-grey");
    }
    __getOrderStatus = orderStatus => {
        if (orderStatus === 'ORDER-PLACED') {
            return localStorage.getItem("orderPlacedStatusText");
        }
        if (orderStatus === 'PREPARING-ORDER') {
            return localStorage.getItem("preparingOrderStatusText");
        }
        if (orderStatus === 'DELIVERY-GUY-ASSIGNED') {
            return localStorage.getItem("deliveryGuyAssignedStatusText");
        }
        if (orderStatus === 'ORDER-ON-ITS-WAY') {
            return localStorage.getItem("onItsWayStatusText");
        }
        if (orderStatus === 'ORDER-PICKED') {
            return localStorage.getItem("orderPickedUpStatusText");
        }

        if (orderStatus === 'ORDER-ACCEPTED') {
            return localStorage.getItem("deliveredStatusText");
        }
        if (orderStatus === 'ORDER-CANCELED') {
            return localStorage.getItem("canceledStatusText");
        }

    };
    _getTotalItemCost = item => {
        let itemCost = parseFloat(item.price) * item.quantity;
        if (item.order_item_addons && item.order_item_addons.length) {
            item.order_item_addons.map(addon => {
                itemCost += parseFloat(addon.addon_price) * item.quantity;
                return itemCost;
            });
        }
        return formatPrice(itemCost);
    };

    componentWillUnmount() {
        document.getElementsByTagName("body")[0].classList.remove("bg-grey");
    }
    render() {

        const { order, user, cancelOrder } = this.props;
        return (
            <React.Fragment>
                <div className="mb-20 bg-white p-3" style={{ borderRadius: "0.275rem" }}>
                    <div className="pull-right">
                        {(order.orderStatus === 'ORDER-PLACED' ||
                            order.orderStatus === 'PREPARING-ORDER' ||
                            order.orderStatus === 'DELIVERY-GUY-ASSIGNED' ||
                            order.orderStatus ==='ORDER-ON-ITS-WAY') && (
                            <DelayLink
                                to={`/running-order/${order.id}`}
                                className="btn btn-square btn-outline-secondary mb-10 order-track-button"
                                delay={250}
                                style={{ position: "relative" }}
                            >
                                {localStorage.getItem("trackOrderText")}
                                <span className="pulse ml-2"></span>
                                <Ink duration="500" />
                            </DelayLink>
                        )}
                    </div>
                    <div className="display-flex">
                        <div className="flex-auto">
                            <button
                                className={`mr-5 btn btn-square btn-outline-secondary min-width-125 mb-10 order-status-button text-muted ${order.orderStatus ===
                                'ORDER-CANCELED' && "text-danger"} `}
                            >
                                {this.__getOrderStatus(order.orderStatus)}
                            </button>
                        </div>
                    </div>
                    <span className="text-muted pull-right" style={{ fontSize: "0.9rem" }}>
						{localStorage.getItem("showFromNowDate") === "true" ? (
                            <Moment fromNow>{order.created_at}</Moment>
                        ) : (
                            <Moment format="DD/MM/YYYY hh:mma">{order.created_at}</Moment>
                        )}
					</span>
                    <div className="flex-auto" style={{maxWidth:'50%'}}>
                        <small className="font-w700" style={{ color: localStorage.getItem("storeColor") }}>
                            {order.ticket_uid}
                        </small>
                    </div>
                    <hr />
                    {order.orderitems.map((item,index) => (
                        <div className="display-flex pb-5" key={index}>
                            <span className="order-item-quantity mr-10">x{item.quantity}</span>
                            <div className="flex-auto text-left">{item.name}</div>
                            <div className="flex-auto text-right">
                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                localStorage.getItem("currencyFormat")}
                                {this._getTotalItemCost(item)}
                                {localStorage.getItem("currencySymbolAlign") === "right" &&
                                localStorage.getItem("currencyFormat")}
                            </div>
                        </div>
                    ))}
                    <React.Fragment>
                        {order.coupon_name && (
                            <div className="display-flex mt-10 font-w700">
                                <React.Fragment>
                                    <div className="flex-auto">Coupon: </div>
                                    <div className="flex-auto text-right">{order.coupon_name}</div>
                                </React.Fragment>
                            </div>
                        )}

                        {order.tax && (
                            <div className="display-flex mt-10 font-w700">
                                <React.Fragment>
                                    <div className="flex-auto">{localStorage.getItem("taxText")}: </div>
                                    <div className="flex-auto text-right text-danger">
                                        <span>+</span>
                                        {order.tax}%
                                    </div>
                                </React.Fragment>
                            </div>
                        )}
                        <div className="display-flex mt-10 font-w700">
                            <div className="flex-auto">{localStorage.getItem("orderTextTotal")}</div>
                            <div className="flex-auto text-right">
                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                localStorage.getItem("currencyFormat")}
                                {order.total}
                                {localStorage.getItem("currencySymbolAlign") === "right" &&
                                localStorage.getItem("currencyFormat")}
                            </div>
                        </div>
                    </React.Fragment>
                    {order.orderStatus !== 'ORDER-ACCEPTED' && (
                        <React.Fragment>
                            <div className="pull-right">
                                <OrderCancelPopup
                                    order={order}
                                    user={user}
                                    cancelOrder={cancelOrder}
                                ></OrderCancelPopup>
                            </div>
                            <div className="clearfix"></div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default OrderList;
