import React, {Component} from "react";
import {Modal} from "react-responsive-modal";



class AlertPopUp extends  Component {

    render() {
        return (<Modal open={this.props.open} onClose={this.props.handleAlertPopUp} closeIconSize={32} center >
            <div className='alert-popup-container' >
                <h4 className='alert-popup-text'>{this.props.text}</h4>
                {this.props.icon && <img src={this.props.icon} className='alert-icon' alt='Action confirmed'/>}
            </div>
            </Modal>
            )
    }
}

export default AlertPopUp;