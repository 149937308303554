import React,{Component} from 'react';
import MetaElement from "../Elements/Meta";
import RestaurantInfoCart from "../Cart/RestaurantInfoCart";
import Loading from "../../helpers/loading";
import {connect} from "react-redux";
import BackWithSearch from "../Elements/BackWithSearch";
import { bookingPosRequest,cancelOrder } from "../../../services/orders/actions";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard,faUtensils,faRedo} from "@fortawesome/free-solid-svg-icons";
import {removeBookingFromLocalStorage} from "../../helpers/generalActions";
import { clearCart } from "../../../services/cart/actions";

import AwesomeButtonStyles from "../../../styles/awesome-button.scss";
import ConfirmationModal from "../Elements/ConfirmationModal";
import AlertPopUp from "../Elements/AlertPopUp";


class BookingRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            intervalId:'',
            placeOrderError:false,
            errorMessage:'',
            posResponse:false,
            allowToPay:false,
            rejected_items_array:[],
            remainingBuyingAmount:0,
            showOrderMoreItems:false,
            orderPrice:null,
            booking:null,
            toggleConfirmationModal:false,
            showGoBackToRestaurants:false,
            toggleAlertPopUp:false
        };
    }
    componentDidMount() {
        const booking=JSON.parse(localStorage.getItem('booking'));

        if(!booking){
            this.props.history.push('/')
        }
        this.setState({booking},()=>  this.posRequest())
    }

    posRequest=()=>{
        const booking =this.state.booking;


        const {user}=this.props.user;
        const token=user.data.auth_token;
        const user_id=user.data.id
       let intervalId= setInterval(()=>{
           this.props.bookingPosRequest(token,booking.id,user_id)


       },5000)

        this.setState({intervalId});
    }
    cancelBooking=()=>{
       this.handleConfirmationModal();
    }
    processPosRequest=pos_response=>{
       if(pos_response.wait){
           return;
       }
        if(!pos_response.withRejectedItems && pos_response.allowToPay){
            this.setState({
                posResponse:true,
                posMessage:pos_response.message,
                allowToPay:true,
                showGoBackToRestaurants:false
            })
            if(pos_response.pos_message){
                this.setState({
                    delayMessage:pos_response.pos_message.delay_message,
                    customerText:pos_response.pos_message.customer_text,
                })
            }
        }else if(pos_response.withRejectedItems){

            this.setState({
                posResponse:true,
                posMessage:pos_response.message,
                allowToPay:pos_response.allowToPay,
                remainingBuyingAmount:pos_response.remainingBuyingAmount,
                orderPrice:pos_response.orderPrice,
                showOrderMoreItems:true,
                showGoBackToRestaurants:false
            })
            if(pos_response.pos_message){
                this.setState({
                    delayMessage:pos_response.pos_message.delay_message,
                    customerText:pos_response.pos_message.customer_text,
                    rejected_items_array:pos_response.pos_message.rejected_items_array
                })
            }
        }else if(pos_response.rejected){
            this.setState({
                posResponse:true,
                posMessage:pos_response.message,
                allowToPay:false,
                remainingBuyingAmount:pos_response.remainingBuyingAmount,
                orderPrice:pos_response.orderPrice,
                showOrderMoreItems:false,
                showGoBackToRestaurants:true
            })
            if(pos_response.pos_message){
                this.setState({
                    customerText:pos_response.pos_message.customer_text,
                })
            }
        }else if(pos_response.timeout){
            this.setState({
                posResponse:true,
                posMessage:pos_response.message,
                allowToPay:false,
                orderPrice:pos_response.orderPrice,
                showGoBackToRestaurants:true
            })
        }

    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId)
    }

    confirmCancelBooking=response=>{
        this.handleConfirmationModal();
        if(response!==1){
            return;
        }
        this.deleteCurrentBooking();
    }

    handleAlertPopUp=()=>{
        this.setState({toggleAlertPopUp:!this.state.toggleAlertPopUp})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
            if(this.props.orders!==prevProps.orders){

                if(this.props.orders.cancel && this.props.orders.cancel.success===true){
                  removeBookingFromLocalStorage();
                  this.confirmBookingDeleted()

                    return;
                }
                 if(this.props.orders.pos_response){
                   if(this.props.orders.pos_response.success&& this.props.orders.pos_response.success===true){
                        this.processPosRequest(this.props.orders.pos_response);
                   }else if(this.props.orders.pos_response.success===false){
                       this.setState({ placeOrderError: true, errorMessage: this.props.orders.pos_response.message});
                   }
                   clearInterval(this.state.intervalId);
               }
            }
    }

    confirmBookingDeleted=()=>{
        setTimeout(()=> this.props.history.push('/'),2000);
        this.handleAlertPopUp();
    }
    handleConfirmationModal=()=>{
        this.setState({toggleConfirmationModal:!this.state.toggleConfirmationModal})
    }

    tryAgain=()=>{

      this.deleteCurrentBooking();
    }


    deleteCurrentBooking=()=>{
        const { user } = this.props.user;
        this.props.clearCart();
        this.props.cancelOrder(user.data.auth_token, user.data.id, this.state.booking.id)
    }

    render() {

        const {restaurant_info}=this.props;


        return (<React.Fragment>
            {this.state.loading? (
                    <Loading />
                ) : (
                <React.Fragment>
                    {this.state.placeOrderError && (
                        <div className="auth-error ongoing-payment">
                            <div className="error-shake">{this.state.errorMessage}</div>
                        </div>
                    )}
                    <BackWithSearch
                        boxshadow={true}
                        has_title={true}
                        title={localStorage.getItem("requestBookingTitle")}
                        disable_search={true}
                    />
                    <MetaElement/>
                    <RestaurantInfoCart restaurant={restaurant_info} />
                    {this.state.posResponse===false ?
                        <div>
                <h4 className='wait-restaurant-text'>{localStorage.getItem("waitPosText")}&nbsp;<span style={{borderBottom:'2px solid'+ (localStorage.getItem('storeColor') ? localStorage.getItem('storeColor') :'#fc8019')}}>{restaurant_info.name}</span><br/>&nbsp;response</h4>
                    <img
                        src={require("../../../assets/img/various/waiter.gif")}
                        className="waiter-loading"
                        alt="loading"

                    />
                        </div> :
                        <div>
                            <div  className='booking-response-container'>
                            <h4 className='wait-restaurant-text' style={{borderBottom:'2px solid'+ (localStorage.getItem('storeColor') ? localStorage.getItem('storeColor') :'#fc8019')}}>{this.state.posMessage}</h4>
                            <br/>
                            {this.state.customerText && <h5 className='pos-message-no-indent highlight-text'>{this.state.customerText}</h5>}
                            {this.state.delayMessage && <h5 className='pos-message'>{this.state.delayMessage}</h5>}

                            </div>
                            <div className='pos-message-container'>
                                {this.state.orderPrice && <h5 className='pos-message-no-indent'>{localStorage.getItem('orderPriceText')}&nbsp;<span style={{color: (localStorage.getItem('storeColor') ? localStorage.getItem('storeColor') :'#fc8019')}}>&pound;&nbsp;{this.state.orderPrice}</span></h5>}

                                {this.state.remainingBuyingAmount>0 && <h5 className='pos-message-no-indent'>{localStorage.getItem('remainingBuyingAmountText')}&nbsp;<span style={{color: (localStorage.getItem('storeColor') ? localStorage.getItem('storeColor') :'#fc8019')}}>&pound;&nbsp;{this.state.remainingBuyingAmount}</span></h5>}

                            {(this.state.rejected_items_array && this.state.rejected_items_array.length>0) &&
                                        <div style={{display:'flex'}}>
                                    <h4>{localStorage.getItem('rejectedItemsHeader')}</h4>
                                <ul className='rejected-items-list'>
                                    {this.state.rejected_items_array.map(rejected_item=>{
                                        return (<li key={rejected_item.id}>{rejected_item.name}</li>)
                                    })}
                                </ul>
                                        </div>
                            }
                            </div>
                            <div className='pos-response-buttons'>
                                {this.state.allowToPay && <AwesomeButton
                                    type="primary"

                                    ripple
                                    onPress={() => {
                                        this.props.history.push("/checkout")
                                    }}
                                >{localStorage.getItem('payButtonText')}&nbsp;<FontAwesomeIcon icon={faCreditCard} /></AwesomeButton> }
                                {this.state.showOrderMoreItems &&
                                <React.Fragment>
                                <AwesomeButton
                                    onPress={() => {
                                        this.props.history.push("../stores/" + localStorage.getItem('restaurant_id'))
                                    }}
                                    type="secondary"
                                    className='order-more-items'
                                    ripple

                                >{localStorage.getItem('orderMoreBtnText')}&nbsp;<FontAwesomeIcon icon={faUtensils} /></AwesomeButton>
                                <AwesomeButton
                                    onPress={() => {
                                        this.cancelBooking();
                                    }}
                                    type="secondary"

                                    ripple

                                >{localStorage.getItem('cancelBtnText')}</AwesomeButton> </React.Fragment>}





                                {this.state.showGoBackToRestaurants &&
                                <AwesomeButton
                                    type="secondary"

                                    ripple
                                    onPress={this.tryAgain}
                                >{localStorage.getItem('tryAgainButtonText')}&nbsp;<FontAwesomeIcon icon={faRedo} /></AwesomeButton>
                                }

                            </div>  </div>}
                </React.Fragment>
            )
            }
            <ConfirmationModal open={this.state.toggleConfirmationModal} handleConfirmationModal={this.handleConfirmationModal} confirmAction={this.confirmCancelBooking} text={localStorage.getItem('cancelBookingConfirmation')}
                               subtext={''}
                               icon={require("../../../assets/img/icons/cancel-order.svg")}
            />
            <AlertPopUp open={this.state.toggleAlertPopUp} handleAlertPopUp={this.handleAlertPopUp} text={localStorage.getItem('orderDeletedSuccessfully')}

                               icon={require("../../../assets/img/icons/confirm-action.svg")}
            />
        </React.Fragment>)
    }

}
const mapStateToProps = (state) => ({
    restaurant_info: state.items.restaurant_info,
    user: state.user,
    orders:state.orders
});

export default connect(
    mapStateToProps,
    {bookingPosRequest,cancelOrder,clearCart}
)(BookingRequest);
