import React, { Component } from "react";

import Ink from "react-ink";
import { formatPrice } from "../../../helpers/formatPrice";
import { connect } from "react-redux";
import {calculatePrice} from '../../../helpers/menuItemsActions'
import {showPrice} from '../../../helpers/formatPrice'

class CartItems extends Component {
    _getItemTotal = (item) => {
        let subItemsTotal = 0;
        let sum = 0;
        if (item.menu_items_menu_groups) {
            subItemsTotal+=calculatePrice(item.menu_items_menu_groups)

        }
        sum += ( parseFloat(item.price)+ subItemsTotal) * item.quantity;
        sum = parseFloat(sum);

        return showPrice(formatPrice(sum))
    };
    _generateKey = (pre) => {
        let newkey = `${pre}_${new Date().getTime()}_${Math.random()
            .toString(36)
            .substring(2, 15) +
        Math.random()
            .toString(36)
            .substring(2, 15)}`;

        return newkey;
    };
    render() {
        const { addProductQuantity, removeProductQuantity, item, removeProduct } = this.props;

        return (
            <React.Fragment>
                <div className="cart-item-meta pt-15 pb-15 align-items-center">
                    <div className="cart-item-name">
                        {localStorage.getItem("showVegNonVegBadge") === "true" && item.is_veg !== null && (
                            <React.Fragment>
                                {item.is_veg ? (
                                    <img
                                        src={require("../../../../assets/img/various/veg-icon.png")}
                                        alt="Veg"
                                        style={{ width: "1rem" }}
                                        className="mr-1"
                                    />
                                ) : (
                                    <img
                                        src={require("../../../../assets/img/various/non-veg-icon.png")}
                                        alt="Non-Veg"
                                        style={{ width: "1rem" }}
                                        className="mr-1"
                                    />
                                )}
                            </React.Fragment>
                        )}

                        <span className={`${!item.is_active && "text-danger"}`}>
							<strong> {item.name}</strong>
						</span>

                        {item.menu_items_menu_groups &&
                        <React.Fragment>
                            <br/>

                            {showChildItems(item.menu_items_menu_groups)}
                        </React.Fragment>
                            }
                    </div>
                    {item.is_active ? (
                        <React.Fragment>
                            <div className="btn-group btn-group-sm cart-item-btn">
                                <button
                                    type="button"
                                    className="btn btn-add-remove"
                                    style={{
                                        color: localStorage.getItem("cartColor-bg"),
                                    }}
                                    onClick={() => removeProductQuantity(item)}
                                >
									<span className="btn-dec">
										{item.quantity === 1 ? (
                                            <i
                                                className="si si-trash"
                                                style={{
                                                    fontSize: "0.8rem",
                                                    top: "-0.2rem",
                                                    WebkitTextStroke: "0.4px #f44336",
                                                    color: "#f44336",
                                                }}
                                            />
                                        ) : (
                                            "-"
                                        )}
									</span>
                                    <Ink duration="500" />
                                </button>
                                <button type="button" className="btn btn-quantity">
                                    {item.quantity}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-add-remove"
                                    style={{
                                        color: localStorage.getItem("cartColor-bg"),
                                    }}
                                    onClick={() => addProductQuantity(item)}
                                >
                                    <span className="btn-inc">+</span>
                                    <Ink duration="500" />
                                </button>
                            </div>
                            <div className="cart-item-price">
                                <React.Fragment>{this._getItemTotal(item)}</React.Fragment>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <button
                                type="button"
                                className="btn btn-add-remove text-danger"
                                style={{
                                    color: localStorage.getItem("cartColor-bg"),
                                    minWidth: "132.05px",
                                }}
                                onClick={() => {
                                    removeProduct(item);
                                }}
                            >
                                {localStorage.getItem("cartRemoveItemButton")}
                            </button>

                            <div className="cart-item-price text-danger">
                                {localStorage.getItem("cartItemNotAvailable")}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
const showChildItems=(menu_items_menu_groups,html=[])=>{


        menu_items_menu_groups.map((menu_group, index) => {
                    if(menu_group.power && menu_group.power>0) {
                        menu_group.menu_items.map((menu_item,index)=>{
                            if(menu_item.quantity && menu_item.quantity>0) {
                                html.push(<React.Fragment key={menu_item.id + index}>

										<span style={{color: "#adadad", fontSize: "0.8rem"}}>
											{(index ? ", " : "") + menu_item.name}
										</span>
                                </React.Fragment>)
                                if (menu_item.menu_items_menu_groups && menu_item.menu_items_menu_groups.length > 0) {
                                    showChildItems(menu_item.menu_items_menu_groups, html);
                                }
                            }
                        })

                    }
                })

    return html;
}
const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    cartTotal: state.total.data,
});

export default connect(
    mapStateToProps,
    {}
)(CartItems);
