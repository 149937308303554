export const updateMenuItem=(menu_items_menu_groups,menu_item_id,menu_group_id,action,item_level,current_level=1,parent_item=null)=>{


    return menu_items_menu_groups.map(menu_group=>{
         menu_group.menu_items.map(menu_item=>{

            if(menu_group.id===parseInt(menu_group_id)&& menu_item.id===parseInt(menu_item_id) && parseInt(item_level)===current_level){

                if(parent_item){
                    updateItemPower(parent_item,action);
                }
                updateItemPower(menu_group,action);
                if(action==='add'){
                    if(!menu_item.quantity){
                        menu_item.quantity=1;
                    }else{
                      ++ menu_item.quantity;
                    }
                }else{
                    if(!menu_item.quantity){
                        menu_item.quantity=0;
                    }else{
                        if(menu_item.quantity>0){
                            -- menu_item.quantity;

                        }
                    }
                }
                return menu_item;
            }else if(menu_item.menu_items_menu_groups && menu_item.menu_items_menu_groups.length>0){

                menu_item.menu_items_menu_groups=  updateMenuItem(menu_item.menu_items_menu_groups,menu_item_id,menu_group_id,action,item_level,++current_level,menu_item);
            return menu_item;
            }
            return menu_item;
        })
       current_level=1;
        return menu_group;
    })
}

export const testMenuGroupForMoreItems=menu_items=>{
    for (let i=0; i<menu_items.length;i++){
        for (let j=0; j<menu_items[i].menu_item_menu_groups.length;j++){
           let menu_group=menu_items[i].menu_item_menu_groups[j];
            if(testMenuGroupPower(menu_group)){
                return true;
            }
            if(menu_group.menu_items){
                let test=testMenuGroupForMoreItems(menu_group.menu_items);
                if(test){
                    return  true;
                }
            }
        }
    }
    return  false;
}

export const calculatePrice=(menu_items_menu_groups,total=0)=>{
     menu_items_menu_groups.forEach(menu_group=> {

        if(menu_group.power && menu_group.power>0){
            menu_group.menu_items.forEach(menu_item=>{
                if(menu_item.quantity && menu_item.quantity>0){
                    total+=menu_item.quantity* parseFloat(menu_item.price);
                    total+= calculatePrice(menu_item.menu_items_menu_groups,total);

                }
           })
        }
    })
return total;
}

export const uncheckUserMenuGroup=(menu_items_menu_groups,menu_group_id,level,current_level=1)=>{

    return menu_items_menu_groups.map(menu_group=> {

        if(parseInt(menu_group.id)===parseInt(menu_group_id) && parseInt(level)===parseInt(current_level)) {
            menu_group.power = 0;

            menu_group.menu_items=menu_group.menu_items.map(menu_item => {
                if (menu_item.quantity && menu_item.quantity>0){
                    menu_item.quantity=0;
                }
                return menu_item;
            })

        }else{
            menu_group.menu_items=  menu_group.menu_items.map(menu_item => {
                menu_item.menu_items_menu_groups=uncheckUserMenuGroup(menu_item.menu_items_menu_groups,menu_group_id,level,++current_level);
            return menu_item;
            })
        }
        current_level=1;
        return menu_group;
    });
}

const testMenuGroupPower=(menu_group)=>{
    return (!menu_group.power || menu_group.power<menu_group.pivot.minQuantity || menu_group.pivot.minQuantity===0)

}
export const testAllGroupsPower=menu_item_menu_groups=>{

    for (let i=0; i<menu_item_menu_groups.length;i++){
        if(!groupPowerIsSatisfied(menu_item_menu_groups[i])){
            return false;
        }
        for (let j=0; j<menu_item_menu_groups[i].menu_items.length;j++){
            let menu_item=menu_item_menu_groups[i].menu_items[j];
            if(!testAllGroupsPower(menu_item.menu_items_menu_groups)){
                return false;
            }
        }
    }

   return true;
}

const groupPowerIsSatisfied=menu_group=>{
    if(!menu_group.power || menu_group.power===0){
        return true;
    }

    return (menu_group.pivot.minQuantity<=menu_group.power)
}
export const verifyGroupsPower=menu_item_menu_groups=>{
  const incomplete_menu_group= menu_item_menu_groups.filter(menu_group=>{

        return (menu_group.power && menu_group.power>0 && menu_group.power<menu_group.pivot.minQuantity )
    })
    return incomplete_menu_group.length>0;
}

export  const  updateItemPower=(product,action)=>{
    if(action==='add'){
        if(!product.power){
            product.power=1;
        }else{
            ++product.power
        }
    }else{
        if(product.power && product.power>0){
            --product.power
        }else {
            product.power=0
        }
    }
}

export const maxPowerReached=menu_item=>{
    return (menu_item.power===menu_item.maxQuantityGroup && menu_item.maxQuantityGroup>0)
}
export const testGroupPower=menu_group=>{
    return (menu_group.power===menu_group.pivot.maxQuantity && menu_group.pivot.maxQuantity>0)

}
