import { UPDATE_CART } from "./actionTypes";
import { formatPrice } from "../../components/helpers/formatPrice";
import {calculatePrice} from '../../components/helpers/menuItemsActions'
export const updateCart = cartProducts => dispatch => {
	let productQuantity = cartProducts.reduce((sum, p) => {
		// sum += p.quantity;
		sum++;
		return sum;
	}, 0);

	let totalPrice = cartProducts.reduce((sum, p) => {

		sum += (p.price * p.quantity) + calculatePrice(p.menu_items_menu_groups);
		sum = parseFloat(sum);
		formatPrice(sum);
		return sum;
	}, 0);

	let cartTotal = {
		productQuantity,
		totalPrice
	};

	dispatch({
		type: UPDATE_CART,
		payload: cartTotal
	});
};

