import React, { Component } from "react";
import {Modal} from "react-responsive-modal";
import 'react-responsive-modal/styles.css';
import DateFnsUtils from "@date-io/date-fns"; // import
import { setPickupTime,setPickupRange,setGuestNumber,setAsap } from "../../../../../services/searchRestaurants/actions";
import { DateTimePicker,TimePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "react-switch";

// import {  TimePicker ,MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from 'moment';
import {connect} from "react-redux";
import {getDefaultBookingTime} from  '../../../../helpers/generalActions';

class RangeSelectRestaurant extends Component {
    state = {
        open: false,
        openTimePicker:false,
        asapSelected:false

    };
    handlePopupOpen = () => {
        this.setState({ open: true });
    };
    handlePopupClose = () => {
        this.setState({ open: false });
    };

    validateBookingTime=time=>{
        const userTime=moment(time).format('DD/MM/YYYY HH:mm')
        console.log('userTime',userTime);
        if(moment(userTime).isBefore(moment().format('DD/MM/YYYY HH:mm'))){

            alert(localStorage.getItem('cantOrderInPastAlert'));
        }else{
            this.props.setPickupTime(time);
            this.toggleDateTimePicker()
        }
    }
    toggleDateTimePicker=()=>{
            this.setState({openTimePicker:!this.state.openTimePicker})
    }
    toggleAsap=()=>{
     let user_asap=1;
     const {asap}=this.props.restaurants;
     if(parseInt(asap)===1){
         user_asap=0;
     }
     this.props.setAsap(user_asap);
    }
    render() {
        const { range,pickup_time,guest_number,asap } = this.props.restaurants;

        return (
            <React.Fragment>

                <Modal open={this.props.open} onClose={this.props.handleRangeSelectModal} closeIconSize={32} center >
                    <div style={{zIndex:88888,position:'relative'}} >
                        <div style={{padding:'15px'}}>
                            <label>{localStorage.getItem("maxRangeLabel")}</label>
                            <div  className='slider-selfpickup-restaurants'>
                                <input type="range"  onChange={e=>this.props.setPickupRange(e.target.value)} step={0.1} style={{width:'100%'}} value={range} min={1} max={12}/>
                            </div>
                            <label className='label-miles'>{range} {range===1 ? 'mile' : 'miles'}</label>
                        </div>

                        <div className="form-group text-center">
                            {this.props.userPreferredSelection === 'DELIVERY' &&
                            <div className=' pull-right'>
                                <label htmlFor="asap">{localStorage.getItem('asapText')}</label><br/>
                                <Switch onChange={this.toggleAsap} checked={parseInt(asap)===1}/>
                            </div>
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {this.props.userPreferredSelection=== 'DINEIN' ?
                                    <DateTimePicker

                                        value={pickup_time}
                                        disablePast
                                        label="Select booking time"
                                        showTodayButton
                                        autoOk
                                        open={this.state.openTimePicker}
                                        onClose={this.toggleDateTimePicker}
                                        okLabel={'Ok'}
                                        onOpen={this.toggleDateTimePicker}
                                        onChange={this.validateBookingTime}
                                    /> :
                                    <TimePicker

                                        ampm={true}
                                        label={localStorage.getItem('orderTimeText')}
                                        value={pickup_time}
                                        todayLabel="now"
                                        autoOk
                                        disablePast
                                        open={this.state.openTimePicker}
                                        onClose={this.toggleDateTimePicker}

                                        okLabel={'Ok'}
                                        onOpen={this.toggleDateTimePicker}
                                        onChange={this.validateBookingTime}
                                    />
                                }
                            </MuiPickersUtilsProvider>


                        </div>

                        {localStorage.getItem("userPreferredSelection")==='DINEIN' &&
                        <div className='form-group' >
                            <label htmlFor="guestNumber">{localStorage.getItem("nrOfGuests")}</label>
                            <select name='guestNumber' defaultValue={guest_number} className='form-control' onChange={e=>this.props.setGuestNumber(e.target.value)}>
                                {selectGuestNumber()}
                            </select>
                        </div>
                        }

                        <div className='text-center col-12'>
                            <button
                                className="btn btn-danger mt-3"
                                onClick={this.props.submitSelfPickupData}
                                style={{
                                    border: "0",
                                    borderRadius: "0",
                                    backgroundColor: localStorage.getItem("storeColor")
                                }}
                            >
                                {localStorage.getItem("saveSelfPickupBtn")}
                            </button>

                        </div>

                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    restaurants: state.restaurants,

});

function selectGuestNumber(){
    var arr = [];
    for (let i = 1; i <= 20; i++) {
        arr.push(<option key={i} value={i}>{i}</option>)
    }
    return arr;
}
export default connect(
    mapStateToProps,
    { setPickupTime,setPickupRange,setGuestNumber,setAsap }
)(RangeSelectRestaurant);
