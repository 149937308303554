import React, { Component } from "react";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {connect} from "react-redux";
import InputMask from 'react-input-mask';
import Modal from  '../../../../helpers/DomModal';

class RangeSelectRestaurant extends Component {
    state = {
        open: false,
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
        expire_month:'',
        expire_year:'',
    };
    handleInputFocus = (input) => {
        this.setState({ focus: input });
    }



    handleInputChange = (name,e) => {

        const {  value } = e.target;

        if(name==='name' && !/^[a-zA-Z\s]*$/.test(value)){
            return;
        }

        this.setState({ [name]: value });
    }
    handleInputChangeMonth=(e)=>{
        let expire_month=e.target.value;

        if(expire_month>12 || expire_month==='00'){
            return
        }


        this.setState({
            expire_month,
        },()=>   this.updateExpiry())
    }

    handleInputChangeYear=(e)=>{

        let expire_year=e.target.value;
        this.updateExpiry();

        this.setState({
            expire_year,

        },()=>   this.updateExpiry())
    }

    updateExpiry=()=>{
        let expire_month=this.state.expire_month;
        let expire_year=this.state.expire_year;
        if(expire_month.length===1){
            expire_month='0'+expire_month;
        }
        if(expire_year.length===1){
            expire_year='0'+expire_year;
        }
        let expiry=expire_month+'/'+expire_year;
        this.setState({expiry});
    }

    triggerPayment=()=>{
        const user_data={};
        if(this.state.number.length<15){
            return
        }
        user_data.card_nr=this.state.number.trim().substr(this.state.number.length - 4);
        user_data.card_holder=this.state.name;
        user_data.expiration_month=this.state.expire_month;
        user_data.expiration_year=this.state.expire_year;

        this.props.makeVivaWalletPayment(user_data)
    }
    render() {

        return (
            <Modal >
                <div  className={`customModal ${this.props.open ?  '' : 'hideModal'}`}>
                    <h3>{localStorage.getItem("payWithVivaWallet")}</h3>
                    <button
                        className="modal-close"
                        onClick={this.props.handleVivaWalletPayment}
                    >X</button>

                        <div className='modalPaymentForm'>
                            <Cards
                                cvc={this.state.cvc}
                                expiry={this.state.expiry}
                                focused={this.state.focus}
                                name={this.state.name}
                                number={this.state.number}
                            />

                                <InputMask mask="9999 9999 9999 9999" maskChar={null} type="text"
                                           name="number"
                                           inputMode="numeric"
                                           id="cardnumber"
                                           placeholder="Card Number"
                                           className='form-control mt-3'
                                           onChange={e=>this.handleInputChange('number',e)}
                                           onFocus={()=>this.handleInputFocus('number')}
                                           data-vp="cardnumber"
                                value={this.state.number}
                                />

                                <input
                                    className='form-control mt-3'
                                    type="text"
                                    name="name"
                                    id="cardHolder"

                                    placeholder="Name"
                                    onChange={e=>this.handleInputChange('name',e)}
                                    onFocus={()=>this.handleInputFocus('name')}
                                    data-vp="cardholder"
                                    value={this.state.name}
                                />
                                <div className="row">
                                    <div className='inline-expiry-container'>
                                    <InputMask mask="99" maskChar={null} type="text"
                                               name="expire_month"
                                               placeholder="mm"
                                               data-vp="month"
                                               id="expirationmonth"
                                               inputMode="numeric"
                                               className='form-control mt-3 inline-card-expiry'
                                               onChange={this.handleInputChangeMonth}
                                               onFocus={()=>this.handleInputFocus('month')}
                                               value={this.state.expire_month}
                                    />&nbsp;/&nbsp;
                                    <InputMask mask="99" maskChar={null} type="text"
                                               name="expire_year"
                                               placeholder="yy"
                                               data-vp="year"
                                               id="expirationyear"
                                               inputMode="numeric"
                                               className='form-control mt-3 inline-card-expiry'
                                               onChange={this.handleInputChangeYear}
                                               onFocus={()=>this.handleInputFocus('year')}
                                               value={this.state.expire_year}
                                    />
                                    </div>
                                    <InputMask mask="9999" maskChar={null} type="text"
                                               inputMode="numeric"
                                               name="cvc"
                                               id="securitycode"
                                               data-vp="cvv"
                                               placeholder="CVC"
                                               className='form-control mt-3 inline-card-input'
                                               onChange={e=>this.handleInputChange('cvc',e)}
                                               onFocus={()=>this.handleInputFocus('cvc')}
                                               value={this.state.cvc}
                                    />
                                    {this.props.vivaWalletInstalments.length>0 &&<select id="drpInstallments" name="drpInstallments" >
                                        {this.props.vivaWalletInstalments.map((instalment,index)=><option key={index}>{instalment}</option>)}
                                    </select>
                                        }

                                </div>
                                <button className='btn btn-primary btn-block mt-3' onClick={this.triggerPayment}>{localStorage.getItem("submitVivaWalletPayment")}</button>

                        </div>


                    </div>
                </Modal>
        );
    }
}

const mapStateToProps = state => ({
    restaurants: state.restaurants,

});


export default connect(
    mapStateToProps,
    { }
)(RangeSelectRestaurant);
