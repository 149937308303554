import { APPLY_COUPON } from "./actionTypes";
import { APPLY_COUPON_URL } from "../../configs";
import Axios from "axios";

export const applyCoupon = (auth_token,
                            discount_code,
                            restaurant_id,
                             booking_id
) => async dispatch => {
    try{

    let {data}=await	Axios.post(APPLY_COUPON_URL, {
        token:auth_token,
		discount_code,
		restaurant_id,
		booking_id
	})

			return dispatch({ type: APPLY_COUPON, payload: data });
	}catch(err){
	console.log(err)
	}
};

export const removeCoupon = () => (dispatch) => {
	const coupon = [];
	return dispatch({ type: APPLY_COUPON, payload: coupon });
};
