import {SET_PICKUP_DATE,SET_PICKUP_RANGE, CLEAR_SEARCH, SEARCH_RESTAURANTS,SET_GUEST_NUMBER,SET_ASAP } from "./actionTypes";
import moment from "moment";

import Axios from "axios";
import { SEARCH_RESTAURANTS_URL } from "../../configs";

export const searchRestaurants = (lat, lng, query) => async dispatch => {
  try{
  let {data}=await  Axios.post(SEARCH_RESTAURANTS_URL, {
        latitude: lat,
        longitude: lng,
        q: query
    })

            return dispatch({ type: SEARCH_RESTAURANTS, payload: data });
      }catch(err){
      console.log(err);
      }
};

export const clearSearch = () => dispatch => {
    const restaurants = [];

    return dispatch({ type: CLEAR_SEARCH, payload: restaurants });
};

export const setPickupTime=(time)=>dispatch=>{

         localStorage.setItem("userOrderTime", moment(time).format('DD/MM/YYYY HH:mm'));


    return dispatch({ type: SET_PICKUP_DATE, payload: time });

}

export const setAsap=asap=>dispatch=>{
        let user_asap=0;
        if(asap){
        user_asap=1;
        }
         localStorage.setItem("asap", user_asap);


    return dispatch({ type: SET_ASAP, payload: user_asap });

}

export const setPickupRange=range=>dispatch=>{

         localStorage.setItem("userRange",range);

    return dispatch({ type: SET_PICKUP_RANGE, payload: range });

}

export const setGuestNumber=guestNumber=>dispatch=>{

         localStorage.setItem("guestNumber",guestNumber);


    return dispatch({ type: SET_GUEST_NUMBER, payload: guestNumber });

}

