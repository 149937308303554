import { NOTIFICATION_TOKEN } from "./actionTypes";
import { NOTIFICATION_TOKEN_URL } from "../../configs";
import Axios from "axios";

export const saveNotificationToken = (push_token, user_id, token) => async(dispatch) => {
    try {
        let {data} = await Axios.post(NOTIFICATION_TOKEN_URL, {
            push_token: push_token,
            user_id: user_id,
            token: token
        });
        return dispatch({type: NOTIFICATION_TOKEN, payload: data});
    }catch (err) {
      console.log(err)
    }
};
