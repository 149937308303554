import { API_URL } from "./website";
import {TEST_URL} from  './website';
export const GET_SETTINGS_URL = TEST_URL + "/get-settings";
export const GET_ALL_LANGUAGES_URL = TEST_URL + "/get-all-languages";
export const GET_SINGLE_LANGUAGE_DATA_URL = TEST_URL + "/get-single-language";
export const GET_PROMO_SLIDER_URL = TEST_URL + "/promo-slider";
export const NOTIFICATION_TOKEN_URL = API_URL + "/save-notification-token";
export const GET_ALERTS_URL = TEST_URL + "/get-user-notifications";
export const MARK_ALL_NOTIFICATIONS_READ_URL = API_URL + "/mark-all-notifications-read";
export const MARK_ONE_NOTIFICATION_READ_URL = API_URL + "/mark-one-notification-read";
export const GET_DELIVERY_RESTAURANTS_URL = TEST_URL + "/get-delivery-restaurants";
export const  GET_DINEIN_RESTAURANTS_URL= TEST_URL + "/get-dinein-restaurants";
export const GET_SELFPICKUP_RESTAURANTS_URL = TEST_URL + "/get-selfpickup-restaurants";
export const GET_SINGLE_ORDER_TO_BE_RATED = API_URL + "/single-ratable-order";
export const GET_RESTAURANTS_SLIDES_URL = TEST_URL + "/get-restaurant-category-slides";
export const LOGIN_USER_URL = TEST_URL + "/login";
export const REGISTER_USER_URL = TEST_URL + "/register";
export const UPDATE_USER_INFO_URL = TEST_URL + "/update-user-info";
export const SEND_OTP_URL = API_URL + "/send-otp";
export const VERIFY_OTP_URL = API_URL + "/verify-otp";
export const CHECK_USER_RUNNING_ORDER_URL = TEST_URL + "/check-running-order";
export const GET_WALLET_TRANSACTIONS_URL = API_URL + "/get-wallet-transactions";
export const SEND_PASSWORD_RESET_EMAIL_URL = API_URL + "/send-password-reset-mail";
export const VERIFY_PASSWORD_RESET_OTP_URL = API_URL + "/verify-password-reset-otp";
export const CHANGE_USER_PASSWORD_URL = API_URL + "/change-user-password";
export const CHANGE_USER_AVATAR_URL = API_URL + "/change-avatar";
export const GET_PAGES_URL = TEST_URL + "/get-pages";
export const GET_SINGLE_PAGE_URL = API_URL + "/get-single-page";
export const GET_ORDERS_URL = TEST_URL + "/get-orders";
export const GET_ORDER_CANCEL_URL = TEST_URL + "/cancel-order";
export const ADD_RATING_URL = API_URL + "/save-new-rating";
export const GET_RATABLE_ORDER_DETAILS_URL = API_URL + "/get-ratable-order";
export const GET_ADDRESS_FROM_COORDINATES = TEST_URL + "/coordinate-to-address";
export const DELETE_ADDRESS_URL = TEST_URL + "/delete-address";
export const GET_ADDRESSES_URL = TEST_URL + "/get-addresses";
export const SAVE_ADDRESS_URL = TEST_URL + "/save-address";
export const SET_DEFAULT_URL = TEST_URL + "/set-default-address";
export const GET_POPULAR_LOCATIONS_URL = TEST_URL + "/popular-geo-locations";
export const SEARCH_LOCATIONS_URL =  API_URL+ "/search-location";
export const SEARCH_RESTAURANTS_URL = TEST_URL + "/search-restaurants";
export const APPLY_COUPON_URL = TEST_URL + "/apply-discount";
export const PLACE_ORDER_URL = TEST_URL + "/place-order";
export const CHECK_CART_ITEMS_AVAILABILITY_URL = TEST_URL + "/check-cart-items-availability";
export const GET_RESTAURANT_INFO_BY_ID_URL = TEST_URL + "/get-restaurant-info-by-id";
export const GET_RESTAURANT_INFO_URL = TEST_URL + "/get-restaurant-info";
export const GET_RESTAURANT_ITEMS_URL = TEST_URL + "/get-restaurant-items";
export const GET_SINGLE_ITEM_URL = TEST_URL + "/get-single-item";
export const CHECK_RESTAURANT_OPERATION_SERVICE_URL = TEST_URL + "/check-restaurant-operation-service";
export const GET_RESTAURANTS_CATEGORIES_URL = TEST_URL + "/get-all-restaurants-categories";
export const GET_FILTERED_RESTAURANTS_URL = TEST_URL + "/get-filtered-restaurants";
export const GET_DELIVERY_GUY_GPS_LOCATION_URL = API_URL + "/delivery/get-delivery-guy-gps-location";
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL = API_URL + "/delivery/set-delivery-guy-gps-location";
export const GET_RESTAURANT_DELIVERY_CHARGES_URL= TEST_URL + "/get-restaurant-delivery-charges";
export const GET_PAYMENT_GATEWAYS_URL = TEST_URL + "/get-payment-gateways";
export const RAZORPAY_PAYMENT_URL = API_URL + "/api/payment/process-razor-pay";
export  const GET_VIVA_WALLET_TOKEN_URL=TEST_URL + "/viva-wallet-token";
export const VIVA_WALLET_PAYMENT_URL=TEST_URL + "/make-viva-wallet-payment";
export const GENERATE_BOOKING_URL=TEST_URL + "/generate-booking";
export const POS_BOOKING_REQUEST_URL=TEST_URL+'/booking-pos-request';
export const CANCEL_BOOKING_URL=TEST_URL+'/cancel-booking';