export const formatPrice = price => {
    if (price) {
        let formatedPrice = parseFloat(price);
        formatedPrice = formatedPrice.toFixed(2);
        return formatedPrice;
    }
    return 0;
};

export  const showPrice=price=>{
    if(localStorage.getItem("currencySymbolAlign") ===
        "left") {
        return localStorage.getItem("currencyFormat")+price;
    }

    return price+localStorage.getItem("currencyFormat");
}