import React, {Component} from 'react';
import {Modal} from "react-responsive-modal";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp,faThumbsDown} from '@fortawesome/free-solid-svg-icons'


class ConfirmationModal extends  Component{


    render(){
        return (
            <Modal open={this.props.open} onClose={this.props.handleConfirmationModal} closeIconSize={32} center >
                <div style={{zIndex:88888}} >
                    {this.props.icon && <img src={this.props.icon} className='confirmation-icon' alt='Confirm payment'/>}
                    <h4 className='confirmation-title'>{this.props.text}</h4>
                    <div className='confirmation-btns-container'>
                    <AwesomeButton
                        color='black'
                        type="secondary"
                        className='btn-confirm'
                        ripple
                        onPress={() => {
                            this.props.confirmAction(0)
                        }}
                    ><FontAwesomeIcon icon={faThumbsDown} /></AwesomeButton>
                    <AwesomeButton
                        type="primary"
                        className='btn-confirm'
                        ripple
                        onPress={() => {
                            this.props.confirmAction(1)
                        }}
                        ><FontAwesomeIcon icon={faThumbsUp} /></AwesomeButton>
                    </div>
                    {this.props.subtext && <h5>{this.props.subtext}</h5>}
                </div>
            </Modal>
        )
    }
}

export default  ConfirmationModal;