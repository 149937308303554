import React, { Component } from "react";

import Ink from "react-ink";
import { applyCoupon } from "../../../../services/coupon/actions";
import { connect } from "react-redux";
import {getApiOrderType} from "../../../helpers/generalActions";

class Coupon extends Component {
    state = {
        inputCoupon: "",
        couponFailed: false,
        couponFailedType: "",
        couponSubtotalMessage: "",
    };
    // componentDidMount() {
    //     localStorage.removeItem("appliedCoupon");
    // }

    componentDidMount() {
        // automatically apply coupon if already exists in localstorage
        // if (localStorage.getItem("appliedCoupon")) {
        //     this.props.applyCoupon(localStorage.getItem("appliedCoupon"), this.props.restaurant_info.id);
        // }
    }
    componentDidUpdate(prevProps) {
        if(this.props.coupon!==prevProps.coupon) {
            const {coupon} = this.props;

            //check if props changed after calling the server
            // if (coupon !== this.props.coupon) {
                //if nextProps.coupon is successful then
                if (coupon.success && coupon.booking) {
                    localStorage.setItem("appliedCoupon", coupon.code);

                    localStorage.setItem("booking", JSON.stringify(coupon.booking));
                    this.setState({couponFailed: false});
                } else {
                    // coupon is invalid
                    localStorage.removeItem("appliedCoupon");
                    this.setState({
                        couponFailed: true,
                        couponFailedType: coupon.type,
                        couponSubtotalMessage: coupon.message,
                    });
                }
            // }
        }
    }
    handleInput = (event) => {
        this.setState({ inputCoupon: event.target.value });
    };

    handleSubmit = (event) => {

        const {user}=this.props.user;
        console.log('user',user);
        const token=user.data.auth_token;
        const {restaurant_info}=this.props;
        if(!user.success){
            this.setState({
                couponFailed: true,

                couponSubtotalMessage: 'Invalid action',
            });
            return false;
        }
        const booking=JSON.parse(localStorage.getItem("booking"));
        if(!booking){
            this.setState({
                couponFailed: true,

                couponSubtotalMessage: 'Invalid action. Something went wrong . No booking available. Please try again',
            });
            return false;
        }
        const booking_id=booking.id;


        this.props.applyCoupon(
            token,
            this.state.inputCoupon,
            restaurant_info.id,
            booking_id
        );
    };

    // componentWillUnmount() {
    //     this.props.coupon.code = undefined;
    // }

    render() {
        const { coupon } = this.props;

        return (
            <React.Fragment>
                <div className="input-group mb-20">
                    <form className="coupon-form" >
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <button className="btn apply-coupon-btn">
                                    <i className="si si-tag" />
                                </button>
                            </div>
                            <input
                                type="text"
                                className="form-control apply-coupon-input"
                                placeholder={localStorage.getItem("cartCouponText")}
                                onChange={this.handleInput}
                                style={{ color: localStorage.getItem("storeColor") }}
                                spellCheck="false"
                            />
                            <div className="input-group-append">
                                <button type="button" className="btn apply-coupon-btn" onClick={this.handleSubmit}>
                                    <i className="si si-arrow-right" />
                                    <Ink duration="500" />
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className="coupon-status">
                        {coupon.code && (
                            <div className="coupon-success pt-10 pb-10">
                                {localStorage.getItem("showCouponDescriptionOnSuccess") === "true" ? (
                                    <React.Fragment>{coupon.description}</React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {'"' + coupon.code + '"'} {localStorage.getItem("cartApplyCoupon")}{" "}
                                        {coupon.discount_type === "PERCENTAGE" ? (
                                            coupon.discount + "%"
                                        ) : (
                                            <React.Fragment>
                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                localStorage.getItem("currencyFormat") + coupon.discount}
                                                {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                coupon.discount + localStorage.getItem("currencyFormat")}{" "}
                                                {localStorage.getItem("cartCouponOffText")}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                        {/* Coupon is not applied, then coupon state is true */}
                        {this.state.couponFailed &&
                        (this.state.couponFailedType === "MINSUBTOTAL" ? (
                            <div className="coupon-fail p-10 pb-10">{this.state.couponSubtotalMessage}</div>
                        ) : (
                            <div className="coupon-fail p-10 pb-10">
                                {this.state.couponSubtotalMessage ? this.state.couponSubtotalMessage :localStorage.getItem("cartInvalidCoupon")}
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    coupon: state.coupon.coupon,
    user:state.user,
    restaurant_info: state.items.restaurant_info,
});

export default connect(
    mapStateToProps,
    { applyCoupon }
)(Coupon);
