import { Link } from "react-router-dom";
import PropTypes from 'prop-types'; // ES6

import React,{Component} from "react";
import { withRouter } from 'react-router-dom';
/**
 * Wraps the React Router Link component and creates a delay after the link is clicked.
 */
 class DelayLink extends Component {

    static defaultProps = {
        delay: 0,
        onDelayStart: () => {},
        onDelayEnd: () => {},
        // history: PropTypes.object.isRequired
    };

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    // static contextTypes = Link.contextTypes;

    constructor(props) {
        super(props);
        this.timeout = null;
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleClick = e => {
        const { replace, to, delay, onDelayStart, onDelayEnd,history } = this.props;


        if (this.props.clickAction) {
            this.props.clickAction();
        }
        onDelayStart(e, to);
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        this.timeout = setTimeout(() => {
            if (replace) {
                 history.replace(to);
            } else {
                history.push(to);
            }
            onDelayEnd(e, to);
        }, delay);
    };
    render() {
        const {  staticContext, ...rest } = this.props;

        const props = rest;
        delete props.delay;
        delete props.onDelayStart;
        delete props.onDelayEnd;
        delete props.clickAction;

        return <Link {...props} onClick={this.handleClick} />;
    }
}

export default withRouter(DelayLink);
