   import {
        GET_TOKEN,
        MAKE_PAYMENT
    } from "./actionTypes";
     import {
            GET_VIVA_WALLET_TOKEN_URL,
            VIVA_WALLET_PAYMENT_URL
        } from "../../configs";
    import Axios from "axios";

          export const getVivaWalletToken =user_id=> async(dispatch) => {
                try{
              let {data}= await Axios.post(GET_VIVA_WALLET_TOKEN_URL, {
                    user_id
                });

                         return dispatch({ type: GET_TOKEN, payload: data });
                   }catch(err){
                   console.log(err)
                   }
            };

            export const makeTransaction=user_data=>async dispatch=>{
                try{

                          let {data}= await Axios.post(VIVA_WALLET_PAYMENT_URL, {
                                chargeToken:user_data.chargeToken,
                                user_id:user_data.user_id,
                                save_payment_method:user_data.save_payment_method,
                                booking_id:user_data.booking_id,
                                card_nr:user_data.card_nr,
                                 card_holder:user_data.card_holder,
                               expiration_month:user_data.expiration_month,
                               expiration_year:user_data.expiration_year,
                               total_amount:user_data.total_amount,

                            });

                                     return dispatch({ type: MAKE_PAYMENT, payload: data });
                               }catch(err){
                               console.log(err)
                               }

            }

