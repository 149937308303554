import { GET_POPULAR_LOCATIONS } from "./actionTypes";
import { GET_POPULAR_LOCATIONS_URL } from "../../configs";
import Axios from "axios";

export const getPopularLocations = () =>async dispatch => {
    try{
   let {data}=await Axios.post(GET_POPULAR_LOCATIONS_URL)

            return dispatch({
                type: GET_POPULAR_LOCATIONS,
                payload: data
            });

        }catch(err){
        console.log(err)
        }
};
