import { GET_PAYMENT_GATEWAYS } from "./actionTypes";
import { GET_PAYMENT_GATEWAYS_URL } from "../../configs";
import Axios from "axios";

export const getPaymentGateways = (token,restaurant_id) => async dispatch => {
    try{
   let {data}=await Axios.post(GET_PAYMENT_GATEWAYS_URL, {
        token: token,
        restaurant_id:restaurant_id
    })

            return dispatch({ type: GET_PAYMENT_GATEWAYS, payload: data });
        }catch(err){
            console.log(err);
        }
};
