import "firebase/messaging";

import * as firebase from "firebase/app";

let messaging;

if (firebase.messaging.isSupported()) {
    if (localStorage.getItem("firebasePublic") !== null || localStorage.getItem("firebaseSenderId") !== null) {
        const initializedFirebaseApp = firebase.initializeApp({
            messagingSenderId: localStorage.getItem("firebaseSenderId")
        });
        messaging = initializedFirebaseApp.messaging();
        messaging.usePublicVapidKey(localStorage.getItem("firebasePublic"));
    } else {
        const initializedFirebaseApp = firebase.initializeApp({
            messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            appId:process.env.REACT_APP_FIREBASE_APP_ID
        });
        messaging = initializedFirebaseApp.messaging();
        messaging.usePublicVapidKey(
            process.env.REACT_APP_FIREBASE_VAPID_KEY
        );
    }
}
export default messaging;
