import React, { Component } from "react";

import { connect } from "react-redux";
import { formatPrice,showPrice } from "../../../helpers/formatPrice";

class BillDetails extends Component {
    state = {
        delivery_charges: 0,
        distance: 0,
        percentage_discount:0

    };

    componentDidMount() {
        if (localStorage.getItem("userSelected") === "SELFPICKUP") {
            this.setState({ delivery_charges: 0 });
        } else {
            // this.setState({ delivery_charges: this.props.restaurant_info.delivery_charges });
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.restaurant_info!==prevProps.restaurant_info) {

            if (localStorage.getItem("userSelected") === "DELIVERY") {
                if (this.props.restaurant_info.delivery_charges !== prevProps.restaurant_info.delivery_charges) {
                    // this.setState({delivery_charges: this.props.restaurant_info.delivery_charges});
                }
            }



            if (this.props.distance) {
                if (localStorage.getItem("userSelected") === "DELIVERY") {
                    if (this.props.restaurant_info.delivery_charge_type === "DYNAMIC") {
                        this.setState({distance: this.props.distance}, () => {
                            //check if restaurant has dynamic delivery charge..
                            // this.calculateDynamicDeliveryCharge();
                        });
                    }
                }
            }
        }
        if(this.props.coupon!==prevProps.coupon){
            this.setDiscountAmount();
        }
    }

    calculateDynamicDeliveryCharge = () => {
        const { restaurant_info } = this.props;

        const distanceFromUserToRestaurant = this.state.distance;
        console.log("Distance from user to restaurant: " + distanceFromUserToRestaurant + " km");

        if (distanceFromUserToRestaurant > restaurant_info.base_delivery_distance) {
            const extraDistance = distanceFromUserToRestaurant - restaurant_info.base_delivery_distance;
            console.log("Extra Distance: " + extraDistance + " km");

            const extraCharge =
                (extraDistance / restaurant_info.extra_delivery_distance) * restaurant_info.extra_delivery_charge;
            console.log("Extra Charge: " + extraCharge);

            let dynamicDeliveryCharge = parseFloat(restaurant_info.base_delivery_charge) + parseFloat(extraCharge);
            console.log("Total Charge: " + dynamicDeliveryCharge);
            if (localStorage.getItem("enDelChrRnd") === "true") {
                dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
            }

            this.setState({ delivery_charges: dynamicDeliveryCharge });
        } else {
            this.setState({ delivery_charges: restaurant_info.base_delivery_charge });
        }
    };
    setDiscountAmount=()=>{
            const {coupon,total}=this.props;
            let percentage_discount = formatPrice((coupon.discount / 100) * parseFloat(total));
            if (coupon.max_discount) {
                if (parseFloat(percentage_discount) >= coupon.max_discount) {
                    percentage_discount = coupon.max_discount;
                }
            }

       this.setState({
           percentage_discount
       })
        }
    // Calculating total with/without coupon/tax
    getTotalAfterCalculation = () => {
        const { total, coupon,delivery_charges,restaurant_charges,application_fee } = this.props;

        let calc = 0;
        if (coupon.code) {
            if (coupon.discount_type === "PERCENTAGE") {
                let percentage_discount = formatPrice((coupon.discount / 100) * parseFloat(total));
                if (coupon.max_discount) {
                    if (parseFloat(percentage_discount) >= coupon.max_discount) {
                        percentage_discount = coupon.max_discount;
                    }
                }

                coupon.appliedAmount =percentage_discount;

                calc = formatPrice(
                    formatPrice(
                        parseFloat(total) -
                        percentage_discount +
                        parseFloat(restaurant_charges || 0.0) +
                        parseFloat(delivery_charges || 0.0)
                    )
                );
            } else {
                calc = formatPrice(
                    parseFloat(total) -
                    (parseFloat(coupon.discount) || 0.0) +
                    ((parseFloat(restaurant_charges) || 0.0) +
                        (parseFloat(delivery_charges) || 0.0))
                );
            }
        } else {
            calc = formatPrice(
                parseFloat(total) +
                parseFloat(restaurant_charges || 0.0) +
                parseFloat(delivery_charges || 0.0)
            );
        }
        if(application_fee && application_fee>0){

            calc=parseFloat(calc)+  parseFloat( this.props.application_fee);
        }
        if (localStorage.getItem("taxApplicable") === "true") {
            calc = formatPrice(
                parseFloat(
                    parseFloat(calc) + parseFloat(parseFloat(localStorage.getItem("taxPercentage")) / 100) * calc
                )
            );
            return calc;
        } else {
            return calc;
        }
    };

    render() {


        const { total, coupon } = this.props;
        return (
            <React.Fragment>
                <div className="bg-white bill-details mb-200">
                    <div className="p-15">
                        <h2 className="bill-detail-text m-0">{localStorage.getItem("cartBillDetailsText")}</h2>
                        {(this.props.application_fee && this.props.application_fee > 0) &&
                        <React.Fragment>
                            <div className="display-flex">
                                <div className="flex-auto">{localStorage.getItem("applicationFeeText")}</div>
                                <div className="flex-auto text-right">
                                    {showPrice(formatPrice( this.props.application_fee))}
                                </div>
                            </div>
                            <hr/>
                        </React.Fragment>
                        }
                        <React.Fragment>
                        <div className="display-flex">
                            <div className="flex-auto">{localStorage.getItem("cartItemTotalText")}</div>
                            <div className="flex-auto text-right">
                                {showPrice(formatPrice(total))}
                            </div>
                        </div>
                        <hr/>
                        </React.Fragment>
                        {coupon.code && (
                            <React.Fragment>
                                <div className="display-flex">
                                    <div className="flex-auto coupon-text">
                                        {localStorage.getItem("cartCouponText")}
                                    </div>
                                    <div className="flex-auto text-right coupon-text">
                                        <span>-</span>
                                        {coupon.discount_type === "PERCENTAGE" ? (
                                            <React.Fragment>
                                                {coupon.discount}% (   {showPrice(formatPrice( this.state.percentage_discount))})
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {showPrice(formatPrice( coupon.discount))}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                                <hr />
                            </React.Fragment>
                        )}
                        {(this.props.restaurant_charges  === null ||this.props.restaurant_charges  === 0) ? null : (
                         <React.Fragment>
                            <div className="display-flex">
                                <div className="flex-auto">{localStorage.getItem("cartRestaurantCharges")}</div>
                                <div className="flex-auto text-right">
                                    {showPrice(formatPrice( this.props.restaurant_charges))}

                                </div>
                            </div>
                             <hr />
                         </React.Fragment>
                        )}


                        {localStorage.getItem("userPreferredSelection") === 'DELIVERY' && (
                            <React.Fragment>
                                <div className="display-flex">
                                    <div className="flex-auto">{localStorage.getItem("cartDeliveryCharges")}</div>
                                    <div className="flex-auto text-right">
                                        {showPrice(formatPrice(this.props.delivery_charges))}

                                    </div>
                                </div>
                                <hr />
                            </React.Fragment>
                        )}
                        {localStorage.getItem("taxApplicable") === "true" && (
                            <React.Fragment>
                                <div className="display-flex">
                                    <div className="flex-auto text-danger">{localStorage.getItem("taxText")}</div>
                                    <div className="flex-auto text-right text-danger">
                                        <span>+</span>
                                        {localStorage.getItem("taxPercentage")}%
                                    </div>
                                </div>
                                <hr />
                            </React.Fragment>
                        )}
                        <div className="display-flex">
                            <div className="flex-auto font-w700">{localStorage.getItem("cartToPayText")}</div>
                            <div className="flex-auto text-right font-w700">
                                {/* Calculating total after discount coupon or without discount coupon */}
                                {showPrice(this.getTotalAfterCalculation())}

                            </div>
                        </div>
                        {localStorage.getItem("userSelected") === "SELFPICKUP" && (
                            <p className="my-2 mt-3 text-danger font-weight-bold">
                                {localStorage.getItem("selectedSelfPickupMessage")}
                            </p>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    coupon: state.coupon.coupon,
    restaurant_info: state.items.restaurant_info,

});

export default connect(
    mapStateToProps,
    {}
)(BillDetails);
