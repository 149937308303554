import { GET_PROMO_SLIDER } from "./actionTypes";
import { GET_PROMO_SLIDER_URL } from "../../configs";
import Axios from "axios";

export const getPromoSlides = location_name =>async( dispatch )=> {
   try {
       let {data} = await Axios.post(GET_PROMO_SLIDER_URL, {
           location_name: location_name
       })
       return dispatch({ type: GET_PROMO_SLIDER, payload: data });

   }catch (err) {
       console.log(err)
   }
};
