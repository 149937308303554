import React, { Component } from "react";
import Modal from  '../../../../helpers/DomModal';

class VivaWallet3DSecure extends Component {
    state = {
        open: false,
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    };

    componentDidMount() {
    }

    render(){
        return (
            <Modal >

                <div className={`customModal ${this.props.open ?  '' : 'hideModal'}`} style={{padding:'5px'}}>
                    <h3>{localStorage.getItem("vivaWallet3dSecure")}</h3>
                    <button
                        className="modal-close"
                        onClick={this.props.handleVivaWallet3dSecure}
                    >X</button>
                    <div id='threed-pane' style={{width:'100%',height:'90%'}} />
                </div>
            </Modal>
        )
    }

}

export  default VivaWallet3DSecure