    import {
        LOGIN_USER,
        REGISTER_USER,
        LOGOUT_USER,
        UPDATE_USER_INFO,
        SEND_OTP,
        VERIFY_OTP,
        RUNNING_ORDER,
        GET_WALLET_TRANSACTIONS,
        SEND_PASSWORD_RESET_EMAIL,
        VERIFY_PASSWORD_RESET_OTP,
        CHANGE_USER_PASSWORD,
    } from "./actionTypes";
    import {
        LOGIN_USER_URL,
        REGISTER_USER_URL,
        UPDATE_USER_INFO_URL,
        SEND_OTP_URL,
        VERIFY_OTP_URL,
        CHECK_USER_RUNNING_ORDER_URL,
        GET_WALLET_TRANSACTIONS_URL,
        SEND_PASSWORD_RESET_EMAIL_URL,
        VERIFY_PASSWORD_RESET_OTP_URL,
        CHANGE_USER_PASSWORD_URL,
        CHANGE_USER_AVATAR_URL,
    } from "../../configs";

    import Axios from "axios";

    export const loginUser = (name, email, password, accessToken, phone, provider, address) => async(dispatch) => {
        try{
      let {data}= await Axios.post(LOGIN_USER_URL, {
            name: name,
            email: email,
            password: password,
            accessToken: accessToken,
            phone: phone,
            provider: provider,
            address: address,
        })


                 return dispatch({ type: LOGIN_USER, payload: data });
           }catch(err){
           console.log(err)
           }
    };

    export const registerUser = (first_name,last_name, email, phone, password,password_confirmation, address) => async dispatch => {
            try{

        let {data}=await Axios.post(REGISTER_USER_URL, {
            first_name,
            last_name,
            email,
            phone,
            password,
            password_confirmation,
            address,
        })



                return dispatch({ type: REGISTER_USER, payload: data });
           }catch(err){
           console.log(err)
           }
    };

    export const logoutUser = (user) => (dispatch) => {
        user = [];
        const walletEmpty = [];

        //remove geoLocation and userSetAddress
        localStorage.removeItem("userSetAddress");
        localStorage.removeItem("geoLocation");
        localStorage.removeItem("lastSavedNotificationToken");

        dispatch({
            type: LOGOUT_USER,
            payload: user,
        });
        dispatch({
            type: RUNNING_ORDER,
            payload: false,
        });
        dispatch({
            type: GET_WALLET_TRANSACTIONS,
            payload: walletEmpty,
        });
    };

    export const updateUserInfo = (user_id, token, unique_order_id) => async dispatch => {
        try{
        let {data}=await Axios.post(UPDATE_USER_INFO_URL, {
            token: token,
            user_id: user_id,
            booking_id: unique_order_id,
        })



                return dispatch({ type: UPDATE_USER_INFO, payload: data });

          }catch(err){
          console.log(err)
          }
    };

    export const checkUserRunningOrder = (user_id, token) => async dispatch => {
     try{
      let {data}=await  Axios.post(CHECK_USER_RUNNING_ORDER_URL, {
            token: token,
            user_id: user_id,
        })


                return dispatch({ type: RUNNING_ORDER, payload: data });
            }catch(err){
                     console.log(err)
                     }
    };

    export const sendOtp = (email, phone, accessToken, provider) => (dispatch) => {
        return Axios.post(SEND_OTP_URL, {
            email: email,
            phone: phone,
            accessToken: accessToken,
            provider: provider,
        })
            .then((response) => {

                const user = response.data;
                return dispatch({ type: SEND_OTP, payload: user });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const verifyOtp = (phone, otp) => (dispatch) => {
        Axios.post(VERIFY_OTP_URL, {
            phone: phone,
            otp: otp,
        })
            .then((response) => {
                const user = response.data;
                return dispatch({ type: VERIFY_OTP, payload: user });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const getWalletTransactions = (token, user_id) => (dispatch) => {
        Axios.post(GET_WALLET_TRANSACTIONS_URL, {
            token: token,
            user_id: user_id,
        })
            .then((response) => {
                const wallet = response.data;
                return dispatch({ type: GET_WALLET_TRANSACTIONS, payload: wallet });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const sendPasswordResetMail = (email) => (dispatch) => {
        Axios.post(SEND_PASSWORD_RESET_EMAIL_URL, {
            email: email,
        })
            .then((response) => {
                const data = response.data;
                return dispatch({ type: SEND_PASSWORD_RESET_EMAIL, payload: data });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const verifyPasswordResetOtp = (email, code) => (dispatch) => {
        Axios.post(VERIFY_PASSWORD_RESET_OTP_URL, {
            email: email,
            code: code,
        })
            .then((response) => {
                const data = response.data;
                return dispatch({ type: VERIFY_PASSWORD_RESET_OTP, payload: data });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const changeUserPassword = (email, code, password) => (dispatch) => {
        Axios.post(CHANGE_USER_PASSWORD_URL, {
            email: email,
            code: code,
            password: password,
        })
            .then((response) => {
                const data = response.data;
                return dispatch({ type: CHANGE_USER_PASSWORD, payload: data });
            })
            .catch(function(error) {
                console.log(error);
            });
    };

    export const changeAvatar = (token, avatar) => () => {
        return Axios.post(CHANGE_USER_AVATAR_URL, {
            token: token,
            avatar: avatar,
        })
            .then((response) => {
                const data = response.data;
                return data;
            })
            .catch(function(error) {
                console.log(error);
            });
    };
